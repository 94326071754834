// Header.js

import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ThemeProvider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Academytheme } from "../../useStyles";
import { config } from "../../Helper";
import { menuItems, cimpmenuItems } from "./HeaderMenu";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ffffff', // Fixed background color
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Fixed box shadow
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginLeft: drawerWidth,
    },
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 18px',
    margin: '0rem 0.5rem',
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '6px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '100%',
      paddingLeft: 26,
      paddingRight: 26,
    },
  },
  menuButton: {
    margin: '8px 0px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: '100%',
    borderRadius: '0px',
    height: 'auto',
  },
  drawerButton: {
    color: '#253B7C'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: '#253B7C'
  },
  logo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  desktopMenu: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileMenu: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  btn: {
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '15px !important',
    },
    margin: '10px 0px !important',
    borderRadius: '25px',
    textTransform: 'unset',
    fontSize: '18px !important',
    fontWeight: '600 !important',
    boxShadow: 'none',
    backgroundColor: '#253B7C',
    color: '#ffffff !important',
    border: 'none',
    "&:hover": {
      boxShadow: 'none',
      backgroundColor: 'white',
      color: '#253B7C !important',
      border: 'none',
    },
  },
  popup: {
    marginTop: '40px',
  },
  menuPaper: {
    borderRadius: '8px',
    top: '60px !important'
  },
  popupitem: {
    fontSize: '18px',
    fontFamily: 'system-ui',
    color: '#253B7C',
    fontWeight: 600,
    "&:hover": {
      color: "black",
    },
  },
  menu: {
    [theme.breakpoints.up('md')]: {
      margin: '0px',
      padding: '4px 16px',
      color: '#253B7C', // Fixed text color
      width: 'fit-content',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '15px',
      padding: '4px 12px',
    },
    margin: '0px',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '1.5rem',
    fontFamily: 'system-ui',
  },
  me: {
    color: '#253B7C',
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: "#253B7C",
    },
  },
  box: {
    backgroundColor: "",
  },
  listItem: {
    paddingLeft: theme.spacing(2),
  },
  collapseIcon: {
    marginLeft: 'auto',
  },
}));

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState({});
  const history = useHistory();
  const myMenu = config.logo === 'Logo_CIMP' ? cimpmenuItems : menuItems;
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSubMenuToggle = (index) => {
    setOpenSubMenus((prevOpenSubMenus) => ({
      ...prevOpenSubMenus,
      [index]: !prevOpenSubMenus[index],
    }));
  };

  // Changed by shrithi --02/01/2025
  // Simplified handleMenuItemClick to handle only internal links
  const handleMenuItemClick = (item) => {
    if (item.submenu.length === 0 && !item.link.startsWith('http')) {
      history.push(item.link);
      setMobileOpen(false);
    }
    // External links are now handled via <a> tags
  };

  const LogoCard = () => {
    const logoname =
      config.logo === 'Logo_CIMP'
        ? 'https://cimp.opengrowth.com/assets/images/logo/cimp-hori-logo.jpeg'
        : 'https://www.opengrowth.com/assets/og/images/opengrowth-logo.png';
    return (
      <Box
        marginBottom={config.logo === 'Logo_CIMP' ? 2 : 0}
        marginTop={config.logo === 'Logo_CIMP' ? 2 : 0}
        className={classes.title}
        height="64px"
        display="flex"
        alignItems="center"
      >
        <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={logoname}
            height={config.logo === 'Logo_CIMP' ? '54' : '68'}
            alt="OpenGrowth Logo"
            loading="lazy"
          />
        </Link>
      </Box>
    );
  };

  const DesktopMenu = () => {
    const [anchorEls, setAnchorEls] = useState({});

    const handleMenuOpen = (event, index) => {
      setAnchorEls((prev) => ({ ...prev, [index]: event.currentTarget }));
    };

    const handleMenuClose = (index) => {
      setAnchorEls((prev) => ({ ...prev, [index]: null }));
    };

    return (
      <Box className={classes.desktopMenu}>
        {myMenu.map((item, index) => (
          <React.Fragment key={index}>
            {/* Changed by shrithi --02/01/2025 */}
            {/* Render external top-level links as <a> with target="_blank" */}
            {item.link?.startsWith('http') ? (
              <Button
                className={`${item.menu === "Login" || item.menu === "Join Now" ? classes.btn : classes.me} ${classes.menu}`}
                variant={item.menu === "Login" || item.menu === "Join Now" ? "contained" : "text"}
                color={item.menu === "Login" || item.menu === "Join Now" ? "#fff" : "inherit"}
                component="a"
                href={item.link}
                target="_blank" // Changed by shrithi --02/01/2025
                rel="noopener noreferrer" // Changed by shrithi --02/01/2025
                endIcon={
                  item.submenu.length > 0 && (
                    anchorEls[index] ? (
                      <TiArrowSortedUp color="#253B7C" size={15} /> // Changed color to match text
                    ) : (
                      <TiArrowSortedDown color="#253B7C" size={15} /> // Changed color to match text
                    )
                  )
                }
              >
                {item.menu}
              </Button>
            ) : (
              <Button
                className={`${item.menu === "Login" || item.menu === "Join Now" ? classes.btn : classes.me} ${classes.menu}`}
                variant={item.menu === "Login" || item.menu === "Join Now" ? "contained" : "text"}
                color={item.menu === "Login" || item.menu === "Join Now" ? "#fff" : "inherit"}
                aria-controls={`desktop-submenu-${index}`}
                aria-haspopup="true"
                endIcon={
                  item.submenu.length > 0 && (
                    anchorEls[index] ? (
                      <TiArrowSortedUp color="#253B7C" size={15} /> // Changed color to match text
                    ) : (
                      <TiArrowSortedDown color="#253B7C" size={15} /> // Changed color to match text
                    )
                  )
                }
                onClick={(event) => {
                  if (item.submenu.length > 0) {
                    if (anchorEls[index]) {
                      handleMenuClose(index);
                    } else {
                      handleMenuOpen(event, index);
                    }
                  } else {
                    handleMenuItemClick(item);
                  }
                }}
              >
                {item.menu}
              </Button>
            )}
            {item.submenu.length > 0 && (
              <Menu
                id={`desktop-submenu-${index}`}
                anchorEl={anchorEls[index]}
                keepMounted
                open={Boolean(anchorEls[index])}
                onClose={() => handleMenuClose(index)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{ paper: classes.menuPaper }}
              >
                {item.submenu.map((subitem, subindex) => (
                  // changed by shrithi --02/01/2025
                  // Handle external links to open in a new tab
                  subitem.link.startsWith('http') ? (
                    <MenuItem
                      key={subindex}
                      component="a"
                      href={subitem.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.popupitem}
                      onClick={() => handleMenuClose(index)}
                    >
                      {subitem.label}
                    </MenuItem>
                  ) : 
						(!subitem.link.startsWith('http') && subitem.link.endsWith('.html')) 
						?
							<MenuItem
							  key={subindex}
							  component="a"
							  href={window.location.origin+subitem.link}
							  rel="noopener noreferrer"
							  className={classes.popupitem}
							  onClick={() => handleMenuClose(index)}
							>
							  {subitem.label}
							</MenuItem>
						:
							(
							<MenuItem
							  key={subindex}
							  component={Link}
							  to={subitem.link}
							  className={classes.popupitem}
							  onClick={() => handleMenuClose(index)}
							>
							  {subitem.label}
							</MenuItem>
						  )
                ))}
              </Menu>
            )}
          </React.Fragment>
        ))}
      </Box>
    );
  };

  const MobileMenu = () => {
    return (
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box
          role="presentation"
          width={drawerWidth}
        >
          <List>
            {myMenu.map((item, index) => (
              <React.Fragment key={index}>
                {/* Changed by shrithi --02/01/2025 */}
                {/* Render external top-level links as <a> with target="_blank" */}
                {item.link?.startsWith('http') ? (
                  <ListItem
                    button
                    component="a"
                    href={item.link}
                    target="_blank" // Changed by shrithi --02/01/2025
                    rel="noopener noreferrer" // Changed by shrithi --02/01/2025
                    className={classes.drawerButton}
                    onClick={() => setMobileOpen(false)} // Close Drawer after navigation
                  >
                    <ListItemText primary={item.menu} />
                  </ListItem>
                ) : (
                  <React.Fragment>
                    <ListItem
                      className={classes.drawerButton}
                      button
                      onClick={() => {
                        if (item.submenu.length > 0) {
                          handleSubMenuToggle(index);
                        } else {
                          handleMenuItemClick(item);
                        }
                      }}
                    >
                      <ListItemText primary={item.menu} />
                      {item.submenu.length > 0 && (
                        <Box className={classes.collapseIcon}>
                          {openSubMenus[index] ? (
                            <TiArrowSortedUp size={20} />
                          ) : (
                            <TiArrowSortedDown size={20} />
                          )}
                        </Box>
                      )}
                    </ListItem>
                    {item.submenu.length > 0 && (
                      <Collapse in={openSubMenus[index]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.submenu.map((subitem, subindex) => (
                            // changed by shrithi --02/01/2025
                            // Handle external links to open in a new tab
                            subitem.link.startsWith('http') 
							? 	(
								  <ListItem
									button
									key={subindex}
									className={classes.nested}
									component="a"
									href={subitem.link}
									target="_blank"
									rel="noopener noreferrer"
									onClick={() => setMobileOpen(false)} // Close Drawer after navigation
								  >
									<ListItemText primary={subitem.label} />
								  </ListItem>
								) 
							:
								(!subitem.link.startsWith('http') && subitem.link.endsWith('.html')) 
								? 	(
									  <ListItem
										button
										key={subindex}
										className={classes.nested}
										component="a"
										href={window.location.origin+subitem.link}
										rel="noopener noreferrer"
										onClick={() => setMobileOpen(false)} // Close Drawer after navigation
									  >
										<ListItemText primary={subitem.label} />
									  </ListItem>
									) 
								:
									(
									  <ListItem
										button
										key={subindex}
										className={classes.nested}
										component={Link}
										to={subitem.link}
										onClick={() => setMobileOpen(false)} // Close Drawer after navigation
									  >
										<ListItemText primary={subitem.label} />
									  </ListItem>
									)
									
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
    );
  };

  return (
    <ThemeProvider theme={Academytheme}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.box} display="flex" justifyContent="space-between" width="100%">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="primary"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <LogoCard />
            <DesktopMenu />
          </Box>
          <MobileMenu />
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
