// src/components/GrowthStudioHome.jsx

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  CircularProgress,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/Venture/promo_image.png';
// src/components/GrowthStudioHome.jsx

import MuiPhoneNumber from 'material-ui-phone-number';

// Import the video for the banner
import bannerVideoSrc from '../assets/Startup_Service2.mp4';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import BenefitsCard from './BenefitsCard';
import FAQSection from '../Experts/FaqSection';

// Import the AlertBox component
import AlertBox from '../Courses/AlertBox';
import PricingCards from './PricingCard';
import VenturePricingPlan from './VenturePricingPlan';
import VentureImage from '../assets/About us/about_us_venture2.jpg';
import {
  generateID,
} from "../../../components/functions/Helper";
// Import Axios
import axios from 'axios';
import { PopupButton } from 'react-calendly';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    border: 'none',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  // Updated banner styles
  bannerContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    border: 'none',
    paddingLeft: '0rem',
    borderRadius: "4px",
    alignItems: 'flex-start',
    overflow: "hidden",
    width: '100%',
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      flexDirection: 'column', // Ensure vertical stacking on small screens
      alignItems: 'left',
    },
  },
  // Remove or adjust imageBox since we'll use video
  imageBox: {
    width: '100%',
    height: '550px', // Adjust the height as needed
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '900px' // Adjust height for smaller screens
    },
    position: 'relative',
    // Add responsive styles for video
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  // Remove bannerImage styles since we're using video
  // Add styles for the video
  bannerVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: '900px',
    },
  },
  contentOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: ' -webkit-fill-available',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(6,4),
    [theme.breakpoints.down("sm")]: {
      backgroundColor: 'rgba(37, 56, 124, 0.6)',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.between("sm", "md")]: {
      backgroundColor: 'rgba(37, 56, 124, 0.6)',
      flexDirection: 'column',
      justifyContent: 'space-around',
      padding: theme.spacing(0, 2),
    },
  },
  contentBox: {
    backgroundColor: 'transparent',
    color: "white", // Adjust text color based on video
    marginTop: 0,
    maxWidth: '60%', // Adjust as needed

    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100% !important',
      width: '100%',
      textAlign: 'left',
      marginTop: theme.spacing(0),
      paddingRight: 0,
    },
  },
  formBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '8px',
    padding: theme.spacing(0, 2, 2, 2), // Reduced padding
    maxWidth: '25%', // Reduced width from 30% to 25%
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
      marginTop: theme.spacing(2),
    },
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the form elements
  },
  bannerButton: {
    marginTop: theme.spacing(0), // Reduced margin-top
    borderRadius: "50px",
    backgroundColor: "#f9bb02",
    color: "black",
    fontWeight: 600,
    fontSize: '0.9rem', // Slightly smaller font
    padding: '6px 16px', // Reduced padding
    textTransform: "none",
    cursor: 'pointer',
    "&:hover": {
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#f9bb02",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      padding: "6px 12px",
    },
  },
  // New styles for smaller TextFields
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.5em', // Reduced height
      padding: '0 12px', // Reduced horizontal padding
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.8rem', // Smaller font size
    },
  },
  subscribeRibbon: {
    backgroundColor: '#25387c', // Standout color for the ribbon
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-4), // Adjust to overlay on the image
    marginBottom: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 4, // Higher z-index to appear above image and content
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      padding: theme.spacing(2),
      marginTop: theme.spacing(-2),
    },
  },
  h1font: {
    fontWeight: "500",
    color: '#f9bb02',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  h6fontheading: {
    color: '#fff',
  },
  h4font: {
    padding: '8px 12px',
    fontWeight: "600",
    fontSize: '49px',
    border: 'none'
  },
  h6font: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  featureCard: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    width: '100%',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
      backgroundColor: '#0000000a',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.6rem',
    fontFamily: 'MyCustomFont, sans-serif',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.1em',
      padding: '0 14px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.9rem',
    },
  },
  searchBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  chipsearchbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '4px',
      paddingRight: '0px',
    },
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#f9bb02',
    color: '#000',
    height: '2.4em',
    fontWeight: 'bold',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#d6a302',
    },
    whiteSpace: 'nowrap',
  },
  newHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  filterChip: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 40%',
      boxSizing: 'border-box',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
    },
  },
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  loadMoreButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '8px 24px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#25387c',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '300px',
    height: '50%',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
  },
  promoTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    marginTop: '32px',
    padding: '12px 12px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  h6: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  // New styles for the video section
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  video: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  span: {
    fontSize: '35px',
  },
  boxContainer: {
    padding: theme.spacing(4),
    marginTop: '5rem',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1),
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    alignItems: 'center',
    borderRadius: '12px',
    background: 'linear-gradient(to bottom, #f9bb02, #f9bb021a)',
    padding: '32px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
  ventureImage: {

    height: '50%',
    width: '50%',
    padding: theme.spacing(0, 0),
    border: '4px solid #25387c',
    margin: theme.spacing(2, 2),
    borderRadius: 8,
    boxShadow: '0 4px 12px rgba(0,0,0,0.6)',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
      transform: 'translateY(-5px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  }
}));

// BannerSlide Component
const BannerSlide = ({ onExplorePricingPlansClick }) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // State for subscribe modal
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [subscribeEmail, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // null, 'success', 'error'
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // States for Contact Us form
  const [name, setFullName] = useState('');
  const [email, setBusinessEmail] = useState('');
  const [company, setOrganization] = useState(''); // New State for Organization
  const [message, setMessage] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(false);

  // States for AlertBox
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState('info'); // 'success' or 'error'

  // State variables for form errors
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [messageError, setMessageError] = useState('');
  // States for Contact Us form
  const [number, setPhone] = useState(''); // Combined country code and phone number
  const [calendlyUrl] = useState('https://calendly.com/opengrowth-support/30min');


  const handleOpenSubscribe = () => {
    setOpenSubscribe(true);
  };

  const handleCloseSubscribe = () => {
    setOpenSubscribe(false);
    setSubscriptionStatus(null);
    setEmail('');
    setIsLoading(false);
  };

  const handleSubscribe = () => {
    setIsLoading(true);
    // Make the API call to /api/subscribe using Axios
    axios.post('/api/send/email', { email })
      .then((response) => {
        console.log(response);
        setSubscriptionStatus('success');
        setEmail('');
      })
      .catch((error) => {
        console.error('Subscription error:', error);
        setSubscriptionStatus('error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validateForm = () => {
    let isValid = true;

    // Reset error messages
    setNameError('');
    setEmailError('');
    setPhoneError('');
    setCompanyError('');
    setMessageError('');

    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^(?!-)(?!.*\.\.)[A-Za-z0-9._%+]+(?<!\.)@(?!-)(?!.*\.\.)[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const blockedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'aol.com', 'icloud.com', 'mail.com'];

    // Validate Full Name
    if (!name.trim()) {
      setNameError('Full Name is required');
      isValid = false;
    }
    else if (!nameRegex.test(name.trim())) {
      setNameError('Full Name can only contain letters and spaces');
      isValid = false;
    }

    if (!email.trim()) {
      setEmailError('Business Email is required');
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address (e.g., user123@example.com)');
      isValid = false;
    }else {
      const emailDomain = email.split('@')[1].toLowerCase();
      if (blockedDomains.includes(emailDomain)) {
          setEmailError('Please use your business email address');
          isValid = false;
      }
  }

    // Validate Phone Number
    const phoneRegex = /^[0-9]{10,15}$/; // Adjust based on requirements
    const onlyNumbers = number.replace(/\D/g, '');
    if (!onlyNumbers.trim()) {
      setPhoneError('Phone Number is required');
      isValid = false;
    } else if (!phoneRegex.test(onlyNumbers)) {
      setPhoneError('Please enter a valid phone number');
      isValid = false;
    }

    // Validate Organization
    if (!company.trim()) {
      setCompanyError('Organization is required');
      isValid = false;
    }

    // Validate Message
    if (!message.trim()) {
      setMessageError('Message is required');
      isValid = false;
    }

    return isValid;
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      // If form is invalid, do not proceed
      return;
    }

    setIsFormLoading(true);

    // Prepare the data to send
    const formData = {
      name,
      email,
      number, // Updated to include country code
      company,
      message,
      "card" : "Main Page",
    };
    console.log(formData);

    // Make the API call to /api/startupService using Axios
    axios.post('/api/startupService', formData)
      .then((response) => {
        console.log(response.data);

        // Handle loading state
        setIsFormLoading(false);

        if (response.data === 'Insert successful') {
          // Show success message
          setAlertMsg("Thank you for contacting us! Our team will contact you shortly to discuss how we can empower your startup with tailored solutions. Let’s grow together!");
          setAlertType('success');
          setAlertOpen(true);

          // Clear the form fields
          setFullName('');
          setBusinessEmail('');
          setPhone('');
          setOrganization('');
          setMessage('');
        }
        else {
          // Show warning message
          setAlertMsg("Email address already exists!");
          setAlertType('warning');
          setAlertOpen(true);
        }
      })
      .catch((error) => {
        console.error('Form submission error:', error);

        // Show error message
        setAlertMsg("Registration failed. Please try again.");
        setAlertType('error');
        setAlertOpen(true);
      })
      .finally(() => {
        setIsFormLoading(false);
      });
  };

  // Handle closing the AlertBox
  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertMsg('');
    setAlertType('info');
  };


  return (
    <>
      <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
        {/* Banner Video */}
        <Box className={classes.imageBox}>
          <video
            src={bannerVideoSrc} // Use the video source instead of image
            autoPlay
            muted
            loop
            playsInline
            className={classes.bannerVideo}
          >
            Your browser does not support the video tag.
          </video>

          {/* Content Overlay */}
          <Box className={classes.contentOverlay}>
            <Box className={classes.contentBox}>
              <Typography
                variant={isSmall ? "h4" : "h1"}
                gutterBottom
                className={`${styleClasses.h1heading} ${classes.h1font}`}
              >
                Venture Assistance
                Services
              </Typography>
              <Typography
                variant={isSmall ? "h6" : "h6"}
                gutterBottom
                className={`${styleClasses.h6banner} ${classes.h6fontheading}`}
              >
                Startup services to support global expansion
                plans and ensure compliance with local
                regulations.
              </Typography>
              <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get In Touch"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
            </Box>

            {/* Contact Us Form */}
            <Box className={classes.formBox}>
              <Typography variant="h6" gutterBottom className={styleClasses.h6font} align='center'>
                Contact Us
              </Typography>
              <form onSubmit={handleContactSubmit} style={{ width: '100%' }}>
                <Grid container spacing={1} style={{ padding: 0 }}> {/* Reduced spacing from 2 to 1 */}
                  <Grid item xs={12} style={{ padding: "8px 4px" }}>
                    <TextField
                      label="Full Name"
                      variant="outlined"
                      fullWidth
                      value={name}
                      onChange={(e) => setFullName(e.target.value)}
                      aria-label="Full Name"
                      size="small" // Added size
                      className={classes.textField} // Applied custom styles
                      // margin="dense" // Reduced margin
                      error={Boolean(nameError)}
                      helperText={nameError}
                      onBlur={() => {
                        if (!name.trim()) {
                          setNameError('Full Name is required');
                        } else {
                          setNameError('');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: "8px 4px" }}>
                    <TextField
                      label="Business Email"
                      type="email"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => setBusinessEmail(e.target.value)}
                      aria-label="Business Email"
                      size="small"
                      className={classes.textField}
                      // margin="dense"
                      error={Boolean(emailError)}
                      helperText={emailError}
                      onBlur={() => {
                        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                        if (!email.trim()) {
                          setEmailError('Business Email is required');
                        } else if (!emailRegex.test(email)) {
                          setEmailError('Please enter a valid email address (e.g., user123@example.com)');
                        } else {
                          setEmailError('');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: '8px 4px' }}>
                    <MuiPhoneNumber
                      defaultCountry={'us'}
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      required
                      value={number}
                      onChange={(value) => setPhone(value)}
                      aria-label="Phone Number"
                      size="small"
                      className={classes.textField}
                      error={Boolean(phoneError)}
                      helperText={phoneError}
                      disableAreaCodes={false}
                      regions={['north-america', 'europe', 'asia', 'australia', 'south-america', 'africa']}
                      // You can customize regions or remove it to include all
                      // Add other props as needed
                      onBlur={() => {
                        const phoneRegex = /^[0-9]{10,15}$/; // Adjust regex based on requirements
                        const onlyNumbers = number.replace(/\D/g, '');
                        if (!onlyNumbers.trim()) {
                          setPhoneError('Phone Number is required');
                        } else if (!phoneRegex.test(onlyNumbers)) {
                          setPhoneError('Please enter a valid phone number');
                        } else {
                          setPhoneError('');
                        }
                      }}
                    />
                  </Grid>

                  {/* Organization Field Placed Before Message */}
                  <Grid item xs={12} style={{ padding: "8px 4px" }}>
                    <TextField
                      label="Organization"
                      variant="outlined"
                      fullWidth
                      value={company}
                      onChange={(e) => setOrganization(e.target.value)}
                      aria-label="Organization"
                      size="small"
                      className={classes.textField}
                      // margin="dense"
                      error={Boolean(companyError)}
                      helperText={companyError}
                      onBlur={() => {
                        if (!company.trim()) {
                          setCompanyError('Organization is required');
                        } else {
                          setCompanyError('');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ padding: "8px 4px" }}>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2} // Increased number of rows for taller text box
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      aria-label="Message"
                      size="small"
                      className={classes.textField}
                      // margin="dense"
                      error={Boolean(messageError)}
                      helperText={messageError}
                      onBlur={() => {
                        if (!message.trim()) {
                          setMessageError('Message is required');
                        } else {
                          setMessageError('');
                        }
                      }}
                    />
                  </Grid>
                  {isFormLoading && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <CircularProgress size={24} /> {/* Reduced size */}
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.bannerButton}
                      disabled={isFormLoading} // Only disable when loading
                    >
                      Get Started
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>

        {/* AlertBox Component */}
        <AlertBox
          open={alertOpen}
          onClose={handleAlertClose}
          msg={alertMsg}
          msg_type={alertType}
        />
      </Box>
    </>
  )
};


// ContentMarketingHome Component
const ContentMarketingHome = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const history = useHistory();
  const pricingCardsRef = useRef(null);
  const [calendlyUrl] = useState('https://calendly.com/opengrowth-support/30min');

  useEffect(() => {
    const mainTitle = "Venture Assistance Services for Business's Growth | OpenGrowth.AI";
    const subtitle = "Boost your business with OpenGrowth.AI’s venture assistance services, offering expert advice and strategies for sustainable growth.";
    document.title = `${mainTitle} - ${subtitle}`;
  }, []);

  // Define the scroll handler
  const handleExplorePricingPlansClick = () => {
    if (pricingCardsRef.current) {
      pricingCardsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box style={{ padding: '8px', paddingTop: 0 }}>
      {/* Header Component (if any) */}
      {/* <Header /> */}

      {/* Banner Carousel */}
      <BannerSlide onExplorePricingPlansClick={handleExplorePricingPlansClick} />

      <BenefitsCard />
      <Box className={classes.boxContainer}>
        <Typography
          variant="h4"
          className={`${styleClasses.h4font}`}
        >
          Our Venture Assistance Services
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.h6}`}>
        OpenGrowth.AI offers Venture Assistance, a full stack of services powered by Fractional Growth Experts, redefining how startups and high-growth businesses access expertise in the age of AI and ML, all at a fraction of the cost.
        </Typography>
        <Box className={classes.imageContainer}>
          <img
            src={VentureImage} // Ensure this path is correct or replace with your desired image URL
            alt="Growth Associate"
            className={classes.ventureImage}
          />
          <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Get In Touch"
              className={`${styleClasses.individualButton}`}
              styles={{padding: '12px 12px', marginTop: '12px'}}
              variant="contained"
            />
        </Box>
      </Box>
      {/* <Box ref={pricingCardsRef}>
        <PricingCards />
      </Box>
      <VenturePricingPlan /> */}

      <FAQSection context="contentMarketing" />
    </Box>
  );
};

export default ContentMarketingHome;
