// src/components/GrowthAssociate.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import GrowthBenefitsCard from '../Experts/GrowthBenefitsCard';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert_banner2.png';
import bannerImage from '../assets/Growth Associate.png';
import axios from 'axios';
import FeaturesSection from './FeatureSection';
import WhyHireGrowthAssociate from './WhyHireGrowthAssociate';

const useStyles = makeStyles((theme) => ({
  /** ======  ADD/EDIT THESE FOR THE HEADER  ====== **/
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  whiteBubble: {
    // This is the rounded white bubble holding the text
    background: 'linear-gradient(to bottom, #f9bb02, #ffffff)',
    color: '#25387c',
    borderBottomRightRadius: '32px',
    borderTopRightRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 9),
    borderRight: '1px solid #25387c',
    borderTop: '1px solid #25387c',
    borderBottom: '1px solid #25387c',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4),
    },
  },
  headerText: {
    // Extra spacing between the two lines of text
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    gap: 8,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      textAlign: 'center',
    },
  },
  headerSubtitle: {
    fontSize: '0.9rem',
    marginBottom: '2px',
    fontWeight: '400',
  },
  headerTitle: {
    fontSize: '1.8rem',
    fontWeight: '600',
  },
  yellowBar: {
    // The yellow bar extending to the right
    flex: 1,
    height: '60px',
    backgroundColor: '#f9bb02',
    marginLeft: '-32px', // So it tucks behind the white bubble
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  /** ======  EXISTING STYLES BELOW  ====== **/
  mainCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    border: 'none',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  h3font: {
    fontWeight: "500",
    color: '#fff',
    border: 'none',
    width: '713px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  h4font: {
    padding: '8px 12px',
    fontWeight: "600",
    fontSize: '49px',
    border: 'none',
  },
  h6font: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  featureCard: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    width: '100%',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
      backgroundColor: '#0000000a',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.6rem',
    fontFamily: 'MyCustomFont, sans-serif',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.1em',
      padding: '0 14px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.9rem',
    },
  },
  searchBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  chipsearchbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '4px',
      paddingRight: '0px',
    },
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#fadf00',
    color: '#000',
    height: '2.4em',
    fontWeight: 'bold',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#d6a302',
    },
    whiteSpace: 'nowrap',
  },
  newHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  filterChip: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 40%',
      boxSizing: 'border-box',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
    },
  },
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  loadMoreButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '8px 24px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#25387c',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '300px',
    height: '50%',
    marginLeft: theme.spacing(4),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
  },
  promoTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    marginTop: '16px',
    padding: "8px 12px",
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  gridCard: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    backgroundColor: '#25387c',
    borderRadius: '4px',
    marginBottom: theme.spacing(1.5),
  },
  gridCard2: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderRadius: '4px',
    marginBottom: theme.spacing(1.5),
  },
  h6: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dialog: {
    padding: '32px',
  },
  textfield: {
    padding: '8px 0px',
  },
}));

const emailRegex = /^(?!-)(?!.*\.\.)[A-Za-z0-9._%+]+(?<!\.)@(?!-)(?!.*\.\.)[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

const GrowthAssociate = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const history = useHistory();
  const [calendlyUrl, setCalendlyUrl] = useState('https://calendly.com/opengrowth-support/30min');

  return (
    <Box style={{ padding: '8px', marginTop: '5rem' }}>
      {/** HEADER SECTION AT THE TOP (like your screenshot) */}
      <Box className={classes.headerContainer}>
        {/* White “bubble” with text */}
        <Box className={classes.whiteBubble}>
          <Box className={classes.headerText}>
            <Typography variant="h6" className={styleClasses.h6banner}>
              Take your business to the next level with a
            </Typography>
            <Typography variant="h4" className={styleClasses.h4font}>
              Growth Associate
            </Typography>
          </Box>
        </Box>
        {/* Yellow bar extending to the right */}
        <Box className={classes.yellowBar} />
      </Box>

      {/** Your existing content below */}
      <FeaturesSection />
      <WhyHireGrowthAssociate />
    </Box>
  );
};

export default GrowthAssociate;
