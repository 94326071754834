import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import missionVisionBg from '../assets/About us/aboutus_mission_vision.png'; // your zigzag/pins image
import useGlobalStyles from '../styles/CourseStyles';

const useStyles = makeStyles((theme) => ({
  missionVisionSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    // Adjust the height as desired, or let the content define the height
    minHeight: '500px',
    backgroundColor: '#ffffff',
    padding: theme.spacing(2, 0),
    // For mobile and iPad (screens below md) switch to a column layout
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    flex: '0 0 60%',
    position: 'relative',
    // For mobile and iPad, make the image container full width
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  image: {
    // Make image fill this container, ensuring it fits nicely
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
  },
  textContainer: {
    flex: '0 0 30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: theme.spacing(0),
    // For mobile and iPad, make the text container full width and add padding
    [theme.breakpoints.down('md')]: {
      flex: 'unset',
      width: 'auto',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
      width: 'auto',
      padding: theme.spacing(3),
    },
  },
  heading: {
    color: '#25387c',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  text: {
    color: '#25387c',
    marginBottom: theme.spacing(4),
  },
}));

const MissionVisionSection = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  return (
    <Box className={classes.missionVisionSection}>
      {/* LEFT SIDE: 60% image with zigzag/pins */}
      <Box className={classes.imageContainer}>
        <img
          src={missionVisionBg}
          alt="Mission Vision Zigzag Background"
          className={classes.image}
        />
      </Box>

      {/* RIGHT SIDE: Text (Mission & Vision) */}
      <Box className={classes.textContainer}>
        <Box>
          <Typography variant="h4" className={styleClasses.h4font}>
            Our Mission
          </Typography>
          <Typography variant="body1" className={styleClasses.description}>
            We are on a mission to create growth in the age of AI with AI Agents
            and Experts working together and scripting the future of work.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h4" className={styleClasses.h4font}>
            Our Vision
          </Typography>
          <Typography variant="body1" className={styleClasses.description}>
            The vision on the future of work is fractional where experience,
            expertise, opportunity, technology and innovation come together to create growth.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MissionVisionSection;
