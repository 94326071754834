// src/components/GrowthBenefitsCard.jsx

import React from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  CardActionArea,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import useGlobalStyles from '../styles/CourseStyles';

// Import your images here.
import benefit1 from '../assets/startup_services_1.png';
import benefit2 from '../assets/Venture/venture_service2.jpg';
import benefit3 from '../assets/Venture/venture_service3.jpg';
import benefit4 from '../assets/Venture/venture_service4.jpg';
import benefit5 from '../assets/Venture/venture_service5.jpg';
import benefit6 from '../assets/Venture/venture_service6.jpg';

const useStyles = makeStyles((theme) => ({
  mainCard: {
    marginTop: '5rem',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    padding: theme.spacing(4),
    border: 'none',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 1),
    },
  },
  titleBox: {
    textAlign: 'left',
    marginBottom: theme.spacing(4),
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '1.75rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      padding: '0px',
    },
  },
  benefitsGrid: {
    marginTop: theme.spacing(2),
  },
  benefitCard: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: '16px 6px',
    border: 'none',
    boxShadow: '0 4px 6px rgba(0,0,0,0.4)',
    backgroundColor: '#565656',
    color: '#fff',
    height: '100%',
    width: '100%',
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
    },
  },
  benefitImage: {
    width: '100%',
    height: '50%',
    objectFit: 'cover',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  h6: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontWeight: '500',
    backgroundColor: '#f9bb02',
    marginBottom: 8,
    borderRadius: '8px',
    padding: '8px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },
  },
  subtitlefont: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  box: {
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
  },
  body1: {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
}));

const BenefitsCard = ({ context = 'allExpert' }) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Added 'link' property to each benefit object for navigation
  const allExpertBenefits = [
    {
      id: 1,
      image: benefit1,
      title: 'Content Marketing',
      description:
        'Engaging, data-driven content tailored to capture your audience’s attention and amplify your brand’s impact.',
      link: '/content-marketing',
    },
    {
      id: 2,
      image: benefit2,
      title: 'Finance & Accounting',
      description:
        'Streamline your financial operations with expert support for budgeting, bookkeeping, tax compliance, and financial reporting.',
      link: '/finance-accounting',
    },
    {
      id: 3,
      image: benefit3,
      title: 'Social Media Marketing',
      description:
        'Tailored campaigns that amplify your brand, boost engagement, and build a loyal audience across different social media channels.',
      link: '/social-media-marketing',
    },
    {
      id: 4,
      image: benefit4,
      title: 'Legal And Compliance',
      description:
        'Ensure your business meets regulatory standards with tailored legal advice and compliance solutions.',
      link: '/legal-compliance',
    },
    {
      id: 5,
      image: benefit5,
      title: 'EOR & HR Services',
      description:
        'Simplify workforce management with Employer of Record (EOR) solutions and HR support for recruitment, payroll, and employee engagement.',
      link: '/eor-hr-services',
    },
    {
      id: 6,
      image: benefit6,
      title: 'Employer and Corporate Branding',
      description:
        'Build a strong reputation and attract top talent with strategic employer and corporate branding to elevate your market presence.',
      link: '/corporate-branding',
    },
  ];

  // Select benefits based on the context
  const benefits = allExpertBenefits;
  const heading = 'Services We Offer';
  const subheading = 'Ready to supercharge your startup?';
  const subheading2 =
    context === 'studio'
      ? 'Growth Studio can help you get recognized as a growth expert by:'
      : 'Our Venture Assistance services offer full stack of support that helps entrepreneurs to stay focused on the core business. Venture Assistance Services range from Social Media, Marketing, Payroll, Human Resources, Finance & Accounting and Legal.';

  return (
    <Card className={classes.mainCard}>
      <Box className={classes.titleBox}>
        <Typography variant="h4" className={styleClasses.h4font} gutterBottom>
          {heading}
        </Typography>
        <Typography
          variant="h6"
          className={`${styleClasses.description} ${classes.subtitlefont}`}
          gutterBottom
        >
          {subheading}
        </Typography>
        <Typography
          variant="h6"
          className={`${styleClasses.description} ${classes.subtitlefont}`}
        >
          {subheading2}
        </Typography>
      </Box>

      <Grid
        container
        spacing={6}
        className={classes.benefitsGrid}
        justifyContent="center"
      >
        {benefits.map((benefit) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={benefit.id}
            style={{ display: 'flex', padding: 24, gap: 16 }}
          >
            <Card className={classes.benefitCard}>
              <CardActionArea
                component={Link}
                to={benefit.link}
                style={{ height: '100%' }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  className={classes.box}
                >
                  <Typography
                    variant="h6"
                    className={`${styleClasses.h6font} ${classes.h6}`}
                    gutterBottom
                  >
                    {benefit.title}
                  </Typography>
                  <img
                    src={benefit.image}
                    alt={benefit.title}
                    className={classes.benefitImage}
                    loading="lazy"
                  />
                  {benefit.description && (
                    <Typography
                      variant="body1"
                      className={`${styleClasses.body1font} ${classes.body1}`}
                    >
                      {benefit.description}
                    </Typography>
                  )}
                </Box>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default BenefitsCard;
