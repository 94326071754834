import {
	Box, Card, CardContent, CardHeader, Grid, makeStyles, ThemeProvider, Typography
} from '@material-ui/core';
import React, { Suspense, useEffect, useState, useContext } from 'react';
import { getCacheDetails } from '../../caching';
import { UserContext } from '../../courses/UserContext';
import {
	User_Course_Skeleton,
	AttendSkeleton, CommunitySkeleton,
	ArticleSkeleton} from '../../Skeleton';
//import config from '../../../translation/config.json';
import { Academytheme } from "../../useStyles";
import { config } from "../../Helper";
import banner from '../Entrepreneur/assets/banner.png';
import useGlobalStyles from '../../../opengrowth/styles/CourseStyles';
import { PopupButton } from 'react-calendly';
import promoImageSrc from '../../../opengrowth/assets/expert1.webp';

//const Drag = React.lazy(() => import('../blogs/drag.js'));
const TabCard = React.lazy(() => import('../../dashboard/tab'));
const PromotionCard = React.lazy(() => import('./PromotionCard'));
const ButtonSection = React.lazy(() => import('../ButtonSection'));
const CommunityCard = React.lazy(() => import('./AppointmentEntrepreneurs'));
const ArticleRead = React.lazy(() => import('../../blogs/PopularBlogs'));
const ConnectedExperts = React.lazy(() => import('./ConnectedExperts'));
const MentorsForDashboard = React.lazy(() => import('./MentorsForDashboard'));
const ViewedRecently = React.lazy(() => import('../../courses/ViewedRecently'));
const SuggestedCourse = React.lazy(() => import('./SuggestedCourse'));
const TimelineMentors = React.lazy(() => import('../TimelineMentors'));
const Mentorship = React.lazy(() => import('../../mentors/dashboard/mentorship'));



let student_id=null
let fname=null
let status=null
let is_mentor=null;
let mentor_req=null;
if(["admin",null,"webinar"].includes(localStorage.getItem('login_token'))==false)
{
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	fname=JSON.parse(localStorage.getItem('ac_login_user'))['fname']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	mentor_req=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_req']
	is_mentor=JSON.parse(localStorage.getItem('ac_login_user'))['is_mentor']
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#f5f5f5',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#f5f5f5',
    color: '#000',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '400px',
    height: '100%',
    marginLeft: theme.spacing(4),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
  },
  promoTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    color: 'black',
    border: 'none',
    padding: '4px 16px',
    fontSize: '1.2rem',
    fontFamily: 'MyCustomFont, sans-serif',
    fontWeight: '600',
    borderRadius: '50px',
    backgroundColor: '#f9bb02',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
}));
  
const ExpertDashboard = () => {

 // const { user, setUser } = useContext(UserContext);
 // const { user, setUser } = useState(null);
  const user = useContext(UserContext);
  const [msg, setMsg] = useState(false);
  const classes = useStyles();
  const [calendlyUrl] = useState('https://calendly.com/opengrowth-support/30min');

  useEffect(() => {
    const getCacheProgress = async () => {
      try {
        const data = await getCacheDetails(
          student_id + "_video_progress_dashboard",
          "getVideoProgress",
          [student_id, 0, 20]
        );
        const k = data["progress"];
        user.set_user_info((user) => ({
          ...user,
          progress_count: k,
        }));
      } catch (error) {
        console.error(error);
      }
    };

    const getCacheBookmark = async () => {
      try {
        const data = await getCacheDetails(
          student_id + "_users_bookmarked_courses_0_to_100",
          "getBookmarkedCourses",
          [0, 100, student_id]
        );
        const i = data.length;
        user.set_user_info((user) => ({
          ...user,
          bookmark_count: i,
        }));
      } catch (error) {
        console.error(error);
      }
    };

    getCacheProgress();
    getCacheBookmark();

    if (status === 'professor' && is_mentor === "mentee" && mentor_req === 1) {
      setMsg(
        "You experts profile activation request is in process. You will be allowed to provide mentorship services once the permission will be granted by the admin."
      );
      setTimeout(() => {
        setMsg(false);
      }, 3000);
    }
  }, []);

	const BannerCard=()=>{
	  const boxStyle = {
		  position: 'relative',
		  minHeight: '150px',
		  border: '1px solid rgb(235, 235, 235)',
      backgroundImage: `url(${banner})`,
		  backgroundSize: 'cover',
		  backgroundPosition: 'center',
		  borderRadius: '12px',
		  padding: 2,
		  margin: 'auto',
		};

		const overlayStyle = {
		  position: 'absolute',
		  top: 0,
		  left: 0,
		  width: '100%',
		  height: '100%',
		  backgroundColor: 'rgb(71 90 109 / 50%)',
		  borderRadius: '12px',
		};

		const textStyle = {
		  position: 'relative',
		  zIndex: 1, 
		  fontSize: '18px',
		  color: '#f2f2f2',
		  textAlign: 'center',
		  marginTop: '40px',
		};

		return(
	
		<Box style={boxStyle}>
		  <div style={overlayStyle}></div>
		  <div style={textStyle}>
			Welcome, {fname}. <br /><br />
			Your expertise is the driving force on OpenGrowth - let's continue shaping success together
			<br />
		  </div>
		</Box>

		)
	}

  return (
  
<ThemeProvider theme={Academytheme}>
  <div className={classes.root}>
    <Box display="none">
      <Typography variant="h6" color="primary" fontWeight={600}>
        {msg}
      </Typography>
    </Box>
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} >
		<BannerCard />
	  </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid container direction="column" spacing={3}>
          {/*<Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <Drag />
            </Suspense>
          </Grid>

	
		  */}
		   
		  
		   {/* <Grid item xs={12} 
			container 
			justifyContent="center"
			alignItems="center">
            <Suspense fallback={<div></div>}>
              <PromotionCard />
            </Suspense>
          </Grid> */}
  {/* {console.log(is_mentor)  }
		{
			status=='professor'
			?
				is_mentor=="mentor"
				?
					<Grid item xs={12}>
					<ConnectedExperts />
					</Grid>
				:''
			:
				<Grid item xs={12}>
				<ConnectedExperts />
				</Grid>			
		} */}

    <ConnectedExperts />
		
		{/*
          <Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <TabCard />
            </Suspense>
          </Grid>


		
		{
			status=='professor' && is_mentor=="mentee"
			?""	
			:<Grid item xs={12}>	
			<TimelineMentors />
			</Grid>
		*/}
		
          {/*<Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <NewCourses />
            </Suspense>
          </Grid>
          {status === 'student' && (
            <Grid item xs={12}>
              <Suspense fallback={<div> <Graph_Skeleton/></div>}>
                <Box marginBottom={2}>
                  <ApexGraphCard />
                </Box>
              </Suspense>
            </Grid>
          )}
          {status === 'student' && (
            <Grid item xs={12}>
              <Suspense fallback={<div> </div>}>
                <MyMentors />
              </Suspense>
            </Grid>
          )}
          {status !== 'professor' || is_mentor !== "mentee" ? (
            <Grid item xs={12}>
              <Mentorship />
            </Grid>
          ) : null}
          {config.webinar.value === 1 && (
            <Grid item xs={12}>
              <UpcomingWebinar />
            </Grid>
          )}
          <Grid item xs={12}>
            <Suspense fallback={<div> <Horizontal_Course_Skeleton/></div>}>
              <CourseBlock />
            </Suspense>
          </Grid>
          <Grid item xs={12}>
            <Suspense fallback={<div> </div>}>
              <Scroll />
            </Suspense>
          </Grid>*/}
		  
		
		   <Grid item xs={12}>
            <Suspense fallback={ <CommunitySkeleton action="vertical" />}>
              <CommunityCard />
            </Suspense>
          </Grid>			  
		   		  
		  
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} className="block">
        <Grid container direction="column" spacing={3}>
        {config.mentorship.value === 1 && (
          <Grid item xs={12}>
            <Suspense fallback={<div> <ArticleSkeleton/></div>}>
              <MentorsForDashboard action="connect" />
            </Suspense>
          </Grid>
          )}	
            <Grid item xs={12}>
              <Suspense fallback={<div> <User_Course_Skeleton/></div>}>
                <SuggestedCourse />
              </Suspense>
            </Grid>
          	  
          
		</Grid>
		</Grid>	

    <Grid item xs={12}>
            <Suspense fallback={ <CommunitySkeleton action="vertical" />}>
              {/* Promotional Card */}
      <Box className={classes.promoCardContainer}>
        {/* Promotional Text Content */}
        <Card className={classes.promoCard}>
          <Box>
            <Typography variant="h4" style={{color: '#000',  fontWeight: 600 }} gutterBottom>
              Looking for a Growth Associate?
            </Typography>
            <Typography variant="h6" className={`${classes.h6}`} gutterBottom>
              Let us help you with your personal branding and drive your outreach -- all at a fraction of the cost of a full-time hire.
            </Typography>
            <br/>
            <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Book a discovery call"
              className={` ${classes.promoButton}`}
              variant="contained"
            />
          </Box>
        </Card>

        {/* Promotional Image with Lazy Loading */}
        <img
          src={promoImageSrc} // Ensure this path is correct or replace with your desired image URL
          alt="Growth Associate"
          className={classes.promoImage}
          loading="lazy" // Native lazy loading
        />
      </Box>
            </Suspense>
          </Grid>		
	</Grid>
    </div>
	</ThemeProvider>

  
  
  )
}

export default ExpertDashboard;