// src/components/CourseRegister.js
// Updated by Shrithi on 2024-12-06

import React, { Component, useContext } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import {
    api, course_list, generateID, getText,
    getUserDetails, translateText, validEmailRegex,
    validPassRegex, validTextRegex, user_view
} from "../../../components/functions/Helper";
import { useStyles } from "../../../components/functions/useStyles";
import Social from '../../functions/users/login/social/index';
import { deleteLocal, getKeys } from "../../../components/functions/caching";
import { UserContext } from '../../../components/functions/courses/UserContext';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PolicyDialog from "../../../components/functions/homepage/PolicyDialog";
import TermsDialog from "../../../components/functions/homepage/TermsDialog";
import MuiPhoneNumber from "material-ui-phone-number";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const locationPath = window.location.pathname;

let student_id = null;
let email = null;
let img = null;
// if (localStorage.getItem('login_token')) {
//     const loginUser = JSON.parse(localStorage.getItem('ac_login_user'));
//     student_id = loginUser['id'];
//     email = loginUser['email'];
//     img = loginUser['img'];
// }

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let query_email = urlParams.get('email');

class CourseRegister extends Component {
    constructor(props) {
        super(props);
        this.translateText = translateText.bind(this);
        this.getText = getText.bind(this);
        this.getUserDetails = getUserDetails.bind(this);
        this.getKeys = getKeys.bind(this);
        this.deleteLocal = deleteLocal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            edu_list: [],
            img: "blank.webp",
            fname: this.init_state('fname'),
            lname: this.init_state("lname"),
            email: this.init_state("email"),
            password: '',
            dob: this.init_state("dob"),
            gender: this.init_state("gender"),
            edu: this.init_state("edu"),
            about: this.init_state("about"),
            number: this.init_state("number"),
            message: this.init_state("message"),
            course: '', // Added course selection
            isOTP: null,
            is_update: false,
            showPassword: false,
            ip_address: null,
            loading: false,
            policy_pop: false,
            terms_pop: false,
            otp: null,
            otp_input: '',
            changeFound: false,
            open: false,
            errors: {
                fname: "",
                lname: "",
                email: "",
                password: "",
                gender: "",
                dob: "",
                edu: "",
                about: "",
                number: "",
                message: "",
                course: "", // Added course error
            }
        };
    }

    init_state = (variable) => {
        if (localStorage.getItem('login_token')) {
            return JSON.parse(localStorage.getItem('ac_login_user'))[variable] || '';
        } else {
            if (variable === "dob") {
                return null; // Initialize as null
            } else if (variable === 'email' && query_email) {
                return query_email;
            } else {
                return '';
            }
        }
    }

    handleChange = event => {
        let errors = this.state.errors;
        const { name, value } = event.target;
        this.setState({ [name]: value, changeFound: true });

        switch (name) {
            case "email":
                errors.email = value ? "" : "Email is required";
                errors.email = validEmailRegex.test(value) ? "" : "Invalid Email ID";
                break;
            case "password":
                if (locationPath !== "/editprofile" || (locationPath === "/editprofile" && value)) {
                    errors.password = value ? "" : "Password is required";
                    errors.password = validPassRegex.test(value) ? "" : "Password must contain 8-12 characters, at least one number, one lowercase letter, one uppercase letter and a special character.";
                } else {
                    errors.password = ""; // Allow empty password on edit profile if not changing
                }
                break;
            case "fname":
            case "lname":
                errors[name] = value
                    ? validTextRegex.test(value)
                        ? "Only text allowed"
                        : ""
                    : `${this.getText(name)} is required`;
                break;
            case "about":
                if (locationPath === '/editprofile') {
                    errors.about = value
                        ? value.length <= 8000
                            ? ""
                            : "Max Length: 8000 characters"
                        : "About me is required";
                }
                break;
            case "number":
                errors.number = value
                    ? /^[0-9]{10,15}$/.test(value)
                        ? ""
                        : "Invalid phone number"
                    : "Phone number is required";
                break;
            case "message":
                if (locationPath !== '/editprofile') {
                    errors.message = value ? "" : "Message is required";
                }
                break;
            case "course": // Handle course selection
                errors.course = value ? "" : "Please select a course";
                break;
            default:
                break;
        }

        if (name === 'gender' && locationPath === '/editprofile') {
            this.setState({ is_update: true });
        }

        this.setState({ errors });
    };

    // Removed handleUserTypeSelection as userType is removed

    verifyOTP = () => {
        this.setState({ open: false });
        if (this.state.otp_input === this.state.otp) {
            this.props.onSubmitSuccess("OTP matched", 'success');
            this.props.onClose();
            this.registerUser();
        } else {
            this.props.onSubmitError("OTP unmatched", 'warning');
            setTimeout(() => { window.location = '/'; }, 3000);
            this.props.onClose();
        }
    }

    getSettings = () => {
        axios.post(api + '/getSettings')
            .then((response) => {
                this.setState({ isOTP: response.data[0]['value'][0] })
            })
            .catch(error => console.log(error));
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleDateChange = (dob) => {
        this.setState({ dob }, () => {
            if (this.state.dob != null) {
                this.setState(prevState => ({
                    errors: { ...prevState.errors, dob: '' }
                }));
            }
        });
    }

    handleOtp = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleClose(pop) {
        setTimeout(() => {
            if (pop === "Terms") {
                this.setState({ terms_pop: false });
            }
            else if (pop === "Policy") {
                this.setState({ policy_pop: false });
            }
            else if (pop === "OTP") {
                this.setState({ open: false });
                window.location = '/';
            }

        }, 1000);
    }

    handlePolicy = () => { this.setState({ policy_pop: true }); }
    handleTerms = () => { this.setState({ terms_pop: true }); }

    checkError = (name, value) => {
        let errorMessage = "";

        const isEditProfile = locationPath === '/editprofile';

        switch (name) {
            case "fname":
            case "lname":
                errorMessage = value
                    ? validTextRegex.test(value)
                        ? "Only text allowed"
                        : ""
                    : `${this.getText(name)} is required`;
                break;
            case "email":
                errorMessage = value
                    ? validEmailRegex.test(value)
                        ? ""
                        : "Invalid Email ID"
                    : "Email is required";
                break;
            case "password":
                if (!isEditProfile || (isEditProfile && value)) {
                    errorMessage = value
                        ? validPassRegex.test(value)
                            ? ""
                            : "Password must contain 8-12 characters, at least one number, one lowercase letter, one uppercase letter, and a special character."
                        : "Password is required";
                }
                break;
            case "gender":
                if (isEditProfile) {
                    errorMessage = value ? "" : "Gender is required";
                }
                break;
            case "dob":
                if (isEditProfile) {
                    let val = this.validate_date_input(value);
                    errorMessage = value == null || value === '' ? "DOB is required" :
                        val === "Invalid Date" || val === "DOB out of range" ?
                            val : "";
                }
                break;
            case "course": // Validate course selection
                if (!isEditProfile) {
                    errorMessage = value ? "" : "Please select a course";
                }
                break;
            case "edu":
                if (isEditProfile) {
                    errorMessage = value ? "" : "Qualification is required";
                }
                break;
            case "about":
                if (isEditProfile) {
                    errorMessage = value
                        ? value.length <= 8000
                            ? ""
                            : "Max Length: 8000 characters"
                        : "About me is required";
                }
                break;
            case "number":
                if (!isEditProfile) {
                    if (!value) {
                        errorMessage = "Phone number is required";
                    } else {
                        const phoneNumber = parsePhoneNumberFromString(value);
                        errorMessage = phoneNumber && phoneNumber.isValid()
                            ? ""
                            : "Invalid phone number";
                    }
                }
                break;
            case "message":
                if (!isEditProfile) {
                    errorMessage = value
                        ? validTextRegex.test(value)
                            ? "Only text allowed"
                            : ""
                        : `Message is required`;
                }
                break;
            default:
                break;
        }

        this.setState(prevState => ({
            errors: { ...prevState.errors, [name]: errorMessage }
        }));

        return errorMessage === "";
    }

    validate_date_input = (date_input) => {
        if (date_input === "Invalid Date" || date_input == null) {
            return "Invalid Date"
        }
        else {
            var nDate = (date_input.getDate()) + "-" + (date_input.getMonth() + 1) + "-" + date_input.getFullYear();
            this.setState({ dob: `${date_input.getFullYear()}-${date_input.getMonth() + 1}-${date_input.getDate()}` });

            let D_1 = ("01-01-1920").split("-");
            let D_2 = ("30-12-2013").split("-");
            let D_3 = nDate.split("-");

            var d1 = new Date(D_1[2], parseInt(D_1[1]) - 1, D_1[0]);
            var d2 = new Date(D_2[2], parseInt(D_2[1]) - 1, D_2[0]);
            var d3 = new Date(D_3[2], parseInt(D_3[1]) - 1, D_3[0]);

            if (d3 > d1 && d3 < d2) {
                return "Valid"
            } else {
                return "DOB out of range"
            }
        }
    }

    validForm = () => {
        let allFilled = true;

        const isEditProfile = locationPath === '/editprofile';
        let requiredFields = ['fname', 'lname', 'email', 'number', 'message', 'course'];

        if (isEditProfile) {
            requiredFields.push('gender', 'dob', 'edu', 'about');
            if (this.state.password) {
                requiredFields.push('password');
            }
        } else {
            // **Modified by Shrithi on 2024-12-06: Ensure course selection is validated after API call**
            // Previously: if (this.state.message) { ... }
            requiredFields.push('course'); // Added 'course' here
        }

        for (let field of requiredFields) {
            let value = this.state[field];
            let isValid = this.checkError(field, value);
            if (!isValid) {
                if (field === "password" && isEditProfile && !value) {
                    continue;
                }
                allFilled = false;
                break;
            }
        }
        return allFilled;
    }

    sendOTP = () => {
        // **Modified by Shrithi on 2024-12-06: Disabled OTP functionality**
        this.props.onSubmitError("OTP functionality is disabled.", 'warning');
        // Close the popup after sending OTP
        this.props.onClose();
    }

    registerUser = () => {
        let param_img = this.state.img;
        if (user_view === "webinar" && param_img) {
            param_img = img;
        }

        this.setState({ loading: true });
        let t = {
            'fname': this.state.fname,
            'lname': this.state.lname,
            'email': this.state.email,
            'mobile': this.state.number, // **Fixed by Shrithi on 2024-12-06: Corrected 'mobile' to 'number'**
            'message': this.state.message,
            'ip': this.state.ip_address,
            'os': osName + " " + osVersion,
            'browser': browserName + " " + browserVersion,
            'course': this.state.course // Include selected course
        }

        axios.post(api + '/addCourse/register', t)
            .then((response) => {
                console.log(response.data);

                this.setState({ loading: false });
                if (response.data === 'Insert successful') {
                    this.props.onSubmitSuccess("You have successfully enrolled!", 'success'); // **Moved by Shrithi to after API call**
                    this.props.onClose();

                    localStorage.setItem('ac_savecourse_user', JSON.stringify(t));


                    // Optionally, redirect after a short delay
                    // setTimeout(() => { window.location = '/dashboard'; }, 6000);
                }
                else {
                    this.props.onSubmitError("Email address already exists!", 'warning');
                    this.props.onClose();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false }); // **Added by Shrithi to stop loading on error**
                this.props.onSubmitError("Enrollment failed. Please try again.", 'error');
                this.props.onClose();
            });
    }

    updateUser = () => {
        let keys = this.deleteLocal(student_id + '_Student_recommendation_dashboard');

        axios.post(api + '/updateUser', {
            'fname': this.state.fname,
            'lname': this.state.lname,
            'user_view': this.state.userType, // Advisor or Entrepreneur (if applicable)
            'edu': this.state.edu,
            'about': this.state.about,
            'type': 'student',
            'ip': this.state.ip_address,
            'os': osName + " " + osVersion,
            'browser': browserName + " " + browserVersion,
            'email': email,
            'dob': this.state.dob,
            'gender': this.state.gender,
            'is_update': this.state.is_update,
            'keys': keys
        })
            .then((response) => {
                this.setState({ loading: false });
                console.log(response.data);
                localStorage.removeItem(email + '_profileData')
                localStorage.removeItem(email)
                this.getUserDetails(email)

                this.props.user.set_user_info((user) => ({
                    name: this.state.fname + " " + this.state.lname,
                    fname: this.state.fname,
                    lname: this.state.lname,
                }));

                this.props.onSubmitSuccess(
                    response.data === "updated" ?
                        "Profile details updated successfully."
                        : "Try again later.",
                    response.data === "updated" ? 'success' : 'error'
                );
                this.props.onClose();
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loading: false }); // **Added by Shrithi to stop loading on error**
                this.props.onSubmitError("Update failed. Please try again.", 'error');
                this.props.onClose();
            });
    }

    sendMessage = () => {
        // **Removed by Shrithi on 2024-12-06: Direct message sending to ensure message appears after API call**
        // this.props.onSubmitSuccess("You’re all set! Welcome to a world of learning with our expertly curated courses. Start exploring and make the most of your journey!", 'success');
        // this.props.onClose();

        // Optionally, clear the form fields after sending the message
        // this.setState({
        //     fname: '',
        //     lname: '',
        //     email: '',
        //     number: '',
        //     message: '',
        //     course: '', // Reset course selection
        //     errors: {
        //         ...this.state.errors,
        //         fname: "",
        //         lname: "",
        //         email: "",
        //         number: "",
        //         message: "",
        //         course: "", // Reset course error
        //     }
        // });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log("hghgg");

        let isValidate = this.validForm();

        if (isValidate) {
            console.log("jghjghg");

            this.setState({ loading: true });

            if (locationPath !== "/editprofile") {
                // **Modified by Shrithi on 2024-12-06: Removed direct call to sendMessage**
                // if (this.state.message) {
                //     this.sendMessage(); // Directly shows message
                // } else {
                if (this.state.isOTP === 1) {
                    this.sendOTP();
                } else {
                    this.registerUser(); // Always call registerUser
                    console.log("comingggggggggg");

                }
                // }
            }
            else {
                if (this.state.changeFound === false) {
                    this.props.onSubmitError("Profile details are not updated.", 'warning');
                    this.setState({ loading: false });
                }
                else {
                    if (this.state.dob != null) {
                        if (user_view === "academy") {
                            this.updateUser();
                        }
                        else if (user_view === "webinar") {
                            this.registerUser();
                        }
                    }
                    else {
                        this.props.onSubmitError("Please update DOB!", 'error');
                        this.setState({ loading: false });
                    }
                }
            }
        }
    }

    componentDidMount() {
        fetch('https://api.ipify.org/?format=json')
            .then(res => res.json()).then((data) => {
                this.setState({ ip_address: data['ip'] })
            }).catch(console.log)

        this.getSettings();
    }

    render() {
        const { errors, loading } = this.state;
        const classes = this.props.classes;
        const styles = { border: { 'borderRadius': '12px' }, height: { 'lineHeight': '1.5rem' }, left: { marginLeft: '16px' } }
        const CustomLabel = ({ title }) => {
            return (
                <Box display="flex">
                    {title}
                    <Box marginLeft={2}>
                        <Typography display="inline" color="error">
                            *
                        </Typography>
                    </Box>
                </Box>
            )
        }

        const SendOTP = () => {
            return (
                <div>
                    <Dialog
                        open={this.state.open} onClose={() => this.handleClose("OTP")}
                        fullWidth
                        maxWidth="xs"
                        classes={{ paper: this.props.classes.dialogContent }} // Apply the updated dialogContent class
                    >
                        <DialogTitle>
                            <Typography variant="h6" color="textPrimary">
                                OTP Verification
                            </Typography>
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText>
                                <Typography variant="subtitle1" color="textSecondary" style={styles.height}>
                                    Please enter the verification code sent to your email address.
                                </Typography>
                                <form role="form">
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        name="otp_input"
                                        onChange={this.handleOtp}
                                    />
                                </form>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button autoFocus onClick={this.verifyOTP} color="primary" size="large">Verify</Button>
                            <Button onClick={() => this.handleClose("OTP")} autoFocus color="primary" size="large">Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }

        const PolicyPop = () => {
            return (
                <>
                    {this.state.policy_pop && (
                        <PolicyDialog
                            open={this.state.policy_pop}
                            parentCallback={() => this.handleClose("Policy")}
                        />
                    )}
                </>
            );
        };

        const TermsPop = () => {
            return (
                <>
                    {this.state.terms_pop && (
                        <TermsDialog
                            open={this.state.terms_pop}
                            parentCallback={() => this.handleClose("Terms")}
                        />
                    )}
                </>
            );
        };

        return (
            <>
                {/* OTP Verification Dialog */}
                {this.state.open === true && <SendOTP />}


                {/* Registration / Get in Touch Form */}
                <Box padding={2}> {/* Add padding around the form */}
                    <form role="form" onSubmit={this.handleSubmit}>
                        <Grid container spacing={2}>
                            {/* First Name */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label={<CustomLabel title="First Name" />}
                                    color="primary"
                                    variant="outlined"
                                    name="fname"
                                    type="text"
                                    onChange={this.handleChange}
                                    error={errors.fname !== ""}
                                    helperText={errors.fname}
                                    value={this.state.fname || ''}
                                    fullWidth
                                />
                            </Grid>

                            {/* Last Name */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label={<CustomLabel title="Last Name" />}
                                    color="primary"
                                    variant="outlined"
                                    name="lname"
                                    type="text"
                                    onChange={this.handleChange}
                                    error={errors.lname !== ""}
                                    helperText={errors.lname}
                                    value={this.state.lname || ''}
                                    fullWidth
                                />
                            </Grid>

                            {/* Email Address */}
                            <Grid item xs={12}>
                                <TextField
                                    label={<CustomLabel title="Email Address" />}
                                    name="email"
                                    type="email"
                                    color="primary"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    error={errors.email !== ""}
                                    helperText={errors.email}
                                    value={this.state.email || ''}
                                    InputProps={{ readOnly: locationPath === "/editprofile" }}
                                    fullWidth
                                />
                            </Grid>

                            {/* Phone Number */}
                            <Grid item xs={12}>
                                <MuiPhoneNumber
                                    defaultCountry={"us"}
                                    label={<CustomLabel title="Phone Number" />}
                                    variant="outlined"
                                    fullWidth
                                    name="number"
                                    value={this.state.number}
                                    onChange={(value) => this.setState({ number: value, changeFound: true }, () => {
                                        // Validate the phone number on change
                                        this.checkError("number", value);
                                    })}
                                    error={errors.number !== ""}
                                    helperText={errors.number}
                                />
                            </Grid>

                            {/* Course Selection Dropdown */}
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth error={errors.course !== ""}>
                                    <InputLabel><CustomLabel title="Select Course" /></InputLabel>
                                    <Select
                                        name="course"
                                        onChange={this.handleChange}
                                        label="Select Course"
                                        value={this.state.course || ''}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {course_list.map((course, index) => (
                                            <MenuItem key={index} value={course}>{course}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Box>
                                    <Typography variant="subtitle2" align="left" color="error">
                                        {errors.course}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* Message */}
                            <Grid item xs={12}>
                                <TextField
                                    label={<CustomLabel title="Why do you want to take this course?" />}
                                    color="primary"
                                    variant="outlined"
                                    name="message"
                                    type="text"
                                    onChange={this.handleChange}
                                    error={errors.message !== ""}
                                    helperText={errors.message}
                                    value={this.state.message || ''}
                                    fullWidth
                                />
                            </Grid>

                            {/* Additional Fields for Edit Profile */}
                            {locationPath === '/editprofile' && (
                                <>
                                    {/* Gender */}
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="outlined" fullWidth
                                            error={errors.gender !== ""}>
                                            <InputLabel>
                                                <CustomLabel title="Gender" />
                                            </InputLabel>
                                            <Select
                                                name="gender"
                                                onChange={this.handleChange}
                                                label="Gender"
                                                value={this.state.gender || ''}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={"Male"}>Male</MenuItem>
                                                <MenuItem value={"Female"}>Female</MenuItem>
                                                <MenuItem value={"Other"}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Box>
                                            <Typography variant="subtitle2" align="left" color="error">
                                                {errors.gender}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* DOB */}
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="outlined" fullWidth
                                            error={errors.dob !== ""}>
                                            <InputLabel id="dob-outlined-label"></InputLabel>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    minDate={new Date("1920-01-01")}
                                                    maxDate={new Date("2013-12-30")}
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    label={<CustomLabel title="DOB" />}
                                                    format="dd-MM-yyyy"
                                                    InputAdornmentProps={{ position: "end" }}
                                                    value={this.state.dob}
                                                    name="dob"
                                                    onChange={this.handleDateChange}
                                                    error={errors.dob !== ""}
                                                    helperText={errors.dob}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </FormControl>
                                    </Grid>

                                    {/* Qualification */}
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" fullWidth
                                            error={errors.edu !== ""}>
                                            <InputLabel><CustomLabel title="Qualification" /></InputLabel>
                                            <Select
                                                name="edu"
                                                onChange={this.handleChange}
                                                label={"Qualification"}
                                                value={this.state.edu || ''}>
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {this.state.edu_list.map((e, index) => (
                                                    <MenuItem key={index} value={e}>{e}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Box>
                                            <Typography variant="subtitle2" align="left" color="error">
                                                {errors.edu}
                                            </Typography>
                                        </Box>
                                    </Grid>

                                    {/* About Me */}
                                    <Grid item xs={12}>
                                        <TextField
                                            error={errors.about !== ""}
                                            fullWidth
                                            name="about"
                                            type="text"
                                            label={<CustomLabel title="About me" />}
                                            multiline
                                            rows={7}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={this.state.about || ''}
                                            helperText={errors.about}
                                        />
                                    </Grid>
                                </>
                            )}

                            {/* Submit Button */}
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        fullWidth={locationPath === "/editprofile" ? false : true}
                                        disabled={loading}
                                        style={{ position: 'relative' }}
                                    >
                                        {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                                        <span style={{ visibility: loading ? 'hidden' : 'visible' }}>
                                            {locationPath === '/editprofile' ? 'Update Profile' : 'Know More'}
                                        </span>
                                    </Button>
                                </Box>
                            </Grid>

                        </Grid>
                    </form>
                </Box>
            </>
        );
    }

}

export default (props) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const user = useContext(UserContext);

    return (
        <CourseRegister
            classes={classes}
            user={user}
            location={location}
            history={history}
            onClose={props.onClose}
            onSubmitSuccess={props.onSubmitSuccess}
            onSubmitError={props.onSubmitError}
        />
    )
}
