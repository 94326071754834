import {
	Avatar, Box,
	Button, Card, CardContent,
	CircularProgress, Grid, TextField, Typography
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AlertBox from "../../AlertBox";
import { deleteLocal } from "../../caching";
import { UserContext } from '../../courses/UserContext';
import { update_lc_mentor, userProfile_path } from "../../Helper";



let fname=null
let name=null
let employer=null
let imgUrl=null
let email=null
let edu=null
let about=null
let experience=null
let industry=null
let user_id=null
let country=null
let interest=null
let status=null
	if(["admin",null].includes(localStorage.getItem('login_token'))==false)
			{
				imgUrl=JSON.parse(localStorage.getItem('ac_login_user'))['img']==''?"blank.webp":JSON.parse(localStorage.getItem('ac_login_user'))['img']
				status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
				fname=JSON.parse(localStorage.getItem('ac_login_user'))['fname']
				employer=JSON.parse(localStorage.getItem('ac_login_user'))['employer']
				name=JSON.parse(localStorage.getItem('ac_login_user'))['name']
				country=JSON.parse(localStorage.getItem('ac_login_user'))['country']
				email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
				edu=JSON.parse(localStorage.getItem('ac_login_user'))['edu']
				industry=JSON.parse(localStorage.getItem('ac_login_user'))['industry']
				interest=JSON.parse(localStorage.getItem('ac_login_user'))['interest']
				about=JSON.parse(localStorage.getItem('ac_login_user'))['about']
				experience=JSON.parse(localStorage.getItem('ac_login_user'))['experience']
				user_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
			}
const styles={img:{width:'140px',height:'140px',border:'2px solid #b0bcc7'},
height:{'line-height':'1.25rem'}}


export default function RequestMentor() {
  const [loading, setLoading] = React.useState(false);
  const [is_req, set_isRequest] = React.useState(false);
  const [mentor, setMentorData] = React.useState([]);
  const [req, setRequest] = React.useState(null);
  const [, forceUpdate] = React.useState(0);
  const [msg, setMessage] = React.useState(false);
  const [errors, setError] = React.useState(false);

  const history = useHistory();

    const handleChange = event => {
		setRequest(event.target.value);
		setError(false)
	}
			
	const deleteKeys=()=>{
		
		    let keys_1=deleteLocal(user_id+"_all_mentors_")
		    let keys_2=deleteLocal(user_id+"_mentors_")
		    let keys_4=deleteLocal(user_id+"_my_mentors_")
			let keys_3=deleteLocal(user_id+"_upcoming_mentorship_")		
			let keys_5=deleteLocal(user_id+"_all_mentors_0_to_")		
				let keys_6="user_"+user_id+"_mentor_slot_"
				let keys=[keys_1,keys_2,keys_3,keys_4,keys_5,keys_6]
				return keys
	}
	const enable_mentorship = () => {
	
		if([null,undefined,''].includes(req))
		{
			setError("Required field")	
		}
		else
		{
			
			if(req.length>1000 )
			{
				setError(" Character count : "+req.length+" | Maximum 1000 characters are allowed.")
			}
			else
			{
				if(req.split(' ').length<100)
				{
					setError(" Word count : "+req.split(' ').length+" | Kindly specify your answer with minimum 100 words.")
				}
				else
				{
					request_mentorship();
				}
			}
		}
	}
	const request_mentorship=()=>{
			setLoading(true)
			let key=(deleteKeys()).toString()
			
			axios.post('/api/enable_mentorship',
			{
			'id':user_id,
			'action':'mentor',
			'mentor_email':email,
			'mentor_name':fname,
			'req_msg':req,
			'key':key
			})
			.then((response) => {
				
				setMessage(response.data['msg'])
				update_lc_mentor(response.data,"update")			
				setLoading(false)
				
				setTimeout(() => {

						setMessage(false)
						if(response.data['is_mentor']="mentor")
						{
							history.push("/dashboard-expert")
						}	
						
						forceUpdate(n => !n)						
						
				}, 3000);
				
				
			});
			
			setTimeout(() => {

						get_req_msg();
						forceUpdate(n => !n)
						
				}, 3000);
	
	}

	const get_req_msg=()=>{
		
		axios.post('/api/find_is_mentor',
		{'email':email})
		.then((response) => {
			
			setMentorData(response.data)
			update_lc_mentor(response.data,"verify")			

			let t=response.data['request_message']
			if(	['false','',null].includes(t)==false)
			{
				setRequest(t);
				set_isRequest(t);
			}
			if(response.data['is_mentor']=="mentor")
			{
				history.push("/dashboard")
			}
			
			
		});
		
	}
	const redirect_to_edit=()=>{
		
		history.push({
		 	pathname: '/editprofile', state: {
				profile_mail:email,
				profile_type:status,
				profile_id:user_id,
		  } })	
	}
	const update_var=() => {
		if(["admin",null].includes(localStorage.getItem('login_token'))==false)
			{
				imgUrl=JSON.parse(localStorage.getItem('ac_login_user'))['img']==''?"blank.webp":JSON.parse(localStorage.getItem('ac_login_user'))['img']
				status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
				fname=JSON.parse(localStorage.getItem('ac_login_user'))['fname']
				employer=JSON.parse(localStorage.getItem('ac_login_user'))['employer']
				name=JSON.parse(localStorage.getItem('ac_login_user'))['name']
				country=JSON.parse(localStorage.getItem('ac_login_user'))['country']
				email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
				edu=JSON.parse(localStorage.getItem('ac_login_user'))['edu']
				industry=JSON.parse(localStorage.getItem('ac_login_user'))['industry']
				interest=JSON.parse(localStorage.getItem('ac_login_user'))['interest']
				about=JSON.parse(localStorage.getItem('ac_login_user'))['about']
				experience=JSON.parse(localStorage.getItem('ac_login_user'))['experience']
				user_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
			}
	}
	useEffect(() => {
    
			
		
			setTimeout(() => {

						
						get_req_msg();
						forceUpdate(n => !n)
				}, 2000);
		
			
		
			setTimeout(() => {

						update_var();
						//console.log(name)
				}, 5000);
		
	},[]);
	
	let info=[
	{title:'Fullname','value':JSON.parse(localStorage.getItem('ac_login_user'))['name'],wd:12},
	{title:'Email','value':JSON.parse(localStorage.getItem('ac_login_user'))['email'],wd:6},
	{title:'Qualification','value':JSON.parse(localStorage.getItem('ac_login_user'))['edu'],wd:6},
	{title:'Interest','value':JSON.parse(localStorage.getItem('ac_login_user'))['interest'],wd:8},
	{title:'Country','value':JSON.parse(localStorage.getItem('ac_login_user'))['country'],wd:4},
	{title:'Industry','value':JSON.parse(localStorage.getItem('ac_login_user'))['industry'],wd:6},
	{title:'Experience','value':JSON.parse(localStorage.getItem('ac_login_user'))['experience'],wd:6}
//	{title:'About','value':about,wd:12}
	];

	const Overview=()=>{

		return  (
		<Card elevation={0}>
				<CardContent style={{padding:'0px'}}>
				<Box marginBottom={2}
					display="block" alignItem="center"
					justifyContent="center"
					
					>
					
					<Typography variant="h5"
					color="primary"
					align="center">
					<Box fontWeight="600" 
					marginTop={8}
					 marginBottom={2}> Want to become a expert?</Box>
					</Typography>
			
			
					<Typography variant="subtitle1"
					color="textSecondary"
					align="center">
					<Box  lineHeight={1.5} > 
					This is the most important part of a mentorship program. It can be quite challenging to create a course out of scratch, but we have covered that for you. We have already organized the resources and templates for you. Watch this video and create your FIRST course with us. This is the most important part of a mentorship program. It can be quite challenging to create a course out of scratch, but we have covered that for you. We have already organized the resources and templates for you. Watch this video and create your FIRST course with us. This is the most important part of a mentorship program. It can be quite challenging to create a course out of scratch, but we have covered that for you. We have already organized the resources and templates for you. Watch this video and create your FIRST course with us.</Box>
					</Typography>
			
				</Box>
				</CardContent>
				
			  </Card>)
	}

	
	const user=useContext(UserContext);
	let img=user.user_info['img']==null?imgUrl:user.user_info['img']
	
    return (
		<Card>
		{
			msg!=false 
			?
				<AlertBox 
				show_status={true} 
				msg={msg} 
				msg_type={"success"} />
			: 
				''	
		}	 
	
		 <Grid container spacing={0}  
			justifyContent="center">
			
			<Grid item xs={10} sm={8} >
			  <Overview />
			</Grid>	
			
			<Grid item xs={10} sm={8} >
			  	<Card elevation={0}>
				<CardContent style={{padding:'0px'}}>
				
					<Box width="fit-content" >
					<Typography variant="h6">
					<Box fontWeight="600" marginTop={2}
					marginBottom={2}> Basic Information </Box>
					</Typography>
					</Box>
					
					
					<Box padding={2}>
						<Grid container 
						spacing={2}  
						>
						
						{
							info.map(({ title, value, wd  }) => {
							return (
							<>
							{value!=null?
							<Grid item xs={10} sm={wd} >
								<TextField  
								label={title}
								name="req"
								color="primary"
								multiline 
								rows={title=="about"?7:1}
								defaultValue={value}  
								InputProps={{ readOnly:true }} fullWidth 
								/>
							</Grid>
							:""}
							</>
							 );
						   })
						}


					<Grid item xs={12}  >
					<Box marginTop={2} display="flex" justifyContent="center">
					<Button
					variant="contained"
							size="large"  
							color="primary" 
							startIcon={<KeyboardBackspaceIcon />}
							onClick={redirect_to_edit} 
							>
							Update your profile
					</Button>
					</Box>
					</Grid>
						
					<Grid item xs={11}  >
					
					
					<Box
					marginTop={3}
					sx={{
					  alignItems: 'center',
					  display: 'flex',
					  flexDirection: 'column'
					}}
				>
					<Box marginTop={4} display="block" textAlign="center" >
						<Avatar variant="circle" src={userProfile_path+img}  style={styles.img} />
					</Box>
					<Box marginTop={1} display="block" textAlign="center" >
					<Typography variant="subtitle1"
					color="textSecondary">
					{mentor['is_mentor']=="mentor"?"Mentor":""}
					</Typography>
					</Box>
			
				</Box>	
					
					<Box
					display="flex" alignItem="center"
					justifyContent="center">
					
					<Typography variant="h5"
					color="primary"
					align="center">
					<Box fontWeight="600" marginTop={2}
					marginBottom={2}> Tell us why you want to become a Expert ? </Box>
					</Typography>
					</Box>
					
								<TextField  
								placeholder="Why to be become a expert" 
								color="primary"
								variant="outlined"
								multiline 
								rows={9}
								fullWidth
								defaultValue={req}  
								onChange={handleChange}
								error={ errors!=false ? errors : ''}
								/>
					
							<Box>
							<Typography variant="subtitle2" align="left" color="error" >
							{ errors!=false ? errors: ''}
							</Typography>
							</Box>
						
					</Grid>
					
					<Grid item xs={10}  >
					<Box marginBottom={5} display="flex"
					justifyContent="center">
					{
						
						mentor['mentor_req']==1
						?
						
							<Button
							variant="contained"
							size="large"  
							color="secondary" 
							>
							Request is pending
							</Button>
						:
							<Button
							variant="contained"
							size="large"  
							color="primary" 
							onClick={enable_mentorship}
							disabled={loading} >
							{loading && <CircularProgress size={24} />}
							{!loading && <span>Get access to experts profile</span>}
							</Button>
						
							
					}
					</Box>
					</Grid>
					
					</Grid>
					</Box>
					
				
				</CardContent>
				</Card>
			</Grid>	

			</Grid>
		
		</Card>
  );
}
