import React from 'react';
import { Box, Typography, Grid, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Example Material-UI icons (use any icons that match your design)
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
// v4 import
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';

// If you're using Calendly popup:
import { PopupButton } from 'react-calendly';
import useGlobalStyles from '../styles/CourseStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(6, 2),
    backgroundColor: '#fff',
    textAlign: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    borderRadius: 4,
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  },
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    color: '#000',
  },
  subTitle: {
    color: '#333',
    marginBottom: theme.spacing(4),
  },
  itemBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  iconWrapper: {
    width: "50%",
    height: 100,
    borderRadius: '0%',
    display: 'flex',
    fontSize: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1.5),
  },
  itemTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
    color: '#000',
  },
  itemSubtitle: {
    color: '#555',
    fontSize: '0.95rem',
    lineHeight: 1.4,
  },
  ctaButtonContainer: {
    marginTop: theme.spacing(4),
  },
  ctaButton: {
    backgroundColor: '#FFCF00',
    color: '#000',
    fontWeight: 600,
    padding: theme.spacing(1.5, 4),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FFB900',
    },
  },
}));

const HowWeSolveIt = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  // Example Calendly URL:
  const calendlyUrl = 'https://calendly.com/opengrowth-support/30min';

  // Data for the solution items
  const solutions = [
    {
      icon: <ReportProblemOutlinedIcon fontSize="large" />,
      title: 'Mitigate Risks',
      subtitle: 'with proactive legal strategies',
    },
    {
      icon: <AccountBalanceOutlinedIcon fontSize="large" />,
      title: 'Ensure Regulatory Compliance',
      subtitle: 'with expert-driven insights',
    },
    {
      icon: <SecurityOutlinedIcon fontSize="large" />,
      title: 'Protect Your Business',
      subtitle: 'with AI-powered contract management',
    },
    {
      icon: <UpdateOutlinedIcon fontSize="large" />,
      title: 'Stay Up-To-Date',
      subtitle: 'with evolving laws and policies',
    },
  ];

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={styleClasses.h4font}>
        How We Solve It
      </Typography>
      <Typography variant="subtitle" className={styleClasses.description}>
        Our AI-powered legal and compliance solutions help you:
      </Typography>

      <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
        {solutions.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box className={classes.itemBox}>
              <Box className={classes.iconWrapper}>{item.icon}</Box>
              <Typography variant="h6" className={styleClasses.h6font}>
                {item.title}
              </Typography>
              <Typography variant="body2" className={styleClasses.subtitlefont}>
                {item.subtitle}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box className={classes.ctaButtonContainer}>
        {/* If using Calendly Popup: */}
        <PopupButton
          url={calendlyUrl}
          rootElement={document.getElementById('root') || undefined}
          text="Talk to a Compliance Expert Now"
          className={styleClasses.individualButton}
        />

        {/* If you prefer a normal Button without Calendly: */}
        {/* <Button variant="contained" className={classes.ctaButton}>
          Talk to a Compliance Expert Now
        </Button> */}
      </Box>
    </Box>
  );
};

export default HowWeSolveIt;
