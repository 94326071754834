import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Dialog,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import coursebusiness from '../assets/coursemain.jpg';
// import coursefeature from '../assets/coursefeature.jpg'; // Not needed now
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert1.webp'; // Ensure path is correct

// Material-UI icons for the 4 challenges
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import GavelIcon from '@material-ui/icons/Gavel';
import DescriptionIcon from '@material-ui/icons/Description';
import SecurityIcon from '@material-ui/icons/Security';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyLoadComponent from '../Homepage/LazyLoadedComponent';
import courses from '../Courses/CourseData';
import { PopupButton } from 'react-calendly';
import HowWeSolveIt from './HowWeSolveIt';

// Lazy loaded components
const PromotionalCard = lazy(() => import('../Courses/CourseFeature'));
const BusinessIndividualSection = lazy(() => import('../Courses/BusinessIndividualSection'));
const FAQSection = lazy(() => import('../Experts/FaqSection'));
const Testimonials = lazy(() => import('../Homepage/Testimonials'));
const BrowseAllCourses = lazy(() => import('../Courses/AllCourseCard'));
const CourseRegister = lazy(() => import('../Courses/CourseRegister'));
const TrendingBlogs = lazy(() => import('../Experts/TrendingBlogs')); // If applicable

// ────────────────────────────────────────────────────────────────────────────────
// MAIN LOCAL STYLES
// ────────────────────────────────────────────────────────────────────────────────
const useStyles = makeStyles((theme) => ({
  filterChipsContainer: {
    gap: 0,
  },
  bannerImageContainer: {
    top: '0px !important',
  },
  newInfoCard: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    background: 'linear-gradient(to bottom, #dcdcdc, #e3e3e3)',
  },
  h5fontPara: {
    fontWeight: 500,
  },
  dialogTitle: {
    padding: '0px 0px !important',
  },
  h5: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  dialogContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    overflowX: 'hidden',
  },
}));

// ────────────────────────────────────────────────────────────────────────────────
// LOCAL STYLES FOR THE WhyLegalCompliance SECTION
// ────────────────────────────────────────────────────────────────────────────────
const useWhyLegalStyles = makeStyles((theme) => ({
  whyLegalContainer: {
    margin: theme.spacing(8, 0),
    padding: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '4px',
    textAlign: 'center',
  },
  h4Title: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  descriptionText: {
    marginBottom: theme.spacing(4),
    maxWidth: 800,
    margin: '0 auto',
  },
  challengesTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  // New style: blue background for icons only
  iconContainer: {
    backgroundColor: '#293c7a',
    padding: theme.spacing(2),
    borderRadius: '5%',
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  challengeIcon: {
    height: '40%',
    width: '80%',
    // fontSize: '10rem',
    color: '#fff',
  },
}));

// ────────────────────────────────────────────────────────────────────────────────
// BANNER COMPONENT
// ────────────────────────────────────────────────────────────────────────────────
const Banner = () => {
  const theme = useTheme();
  const classes = useGlobalStyles();
  const styleClasses = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [calendlyUrl] = useState('https://calendly.com/opengrowth-support/30min');

  return (
    <Box className={classes.bannerContainer}>
      <Box className={classes.bannerTextContainer}>
        <Typography variant="h1" className={classes.h1banner} gutterBottom>
          Ensure Compliance with AI-Powered <span className={classes.span}>Legal & Compliance Experts</span>
        </Typography>
        <Typography variant="h6" className={`${classes.h6banner} ${classes.h6HeadingDesc}`} width={'65%'}>
          Stay ahead of regulatory risks with tailored legal and compliance solutions designed for your business.
        </Typography>
        <Box className={`${classes.filterChipsContainer}`}>
          <PopupButton
            url={calendlyUrl}
            rootElement={document.getElementById('root') || undefined}
            text="Get a Free Compliance Consultation"
            className={`${classes.individualButton} ${classes.promoButton}`}
            variant="contained"
          />
        </Box>
      </Box>
      <Box className={`${classes.bannerImageContainer} ${styleClasses.bannerImageContainer}`}>
        <img src={coursebusiness} alt="Banner" className={classes.bannerImage} loading="lazy" />
      </Box>
    </Box>
  );
};

// ────────────────────────────────────────────────────────────────────────────────
// WHYLEGALCOMPLIANCE COMPONENT
// ────────────────────────────────────────────────────────────────────────────────
const WhyLegalCompliance = () => {
  const classes = useGlobalStyles();
  const styleClasses = useWhyLegalStyles();
  const theme = useTheme();
  useMediaQuery(theme.breakpoints.down('md'));

  // Data for the 4 "Common Challenges" cards
  const challenges = [
    {
      icon: <HelpOutlineIcon className={styleClasses.challengeIcon} />,
      title: 'Uncertainty around industry regulations',
    },
    {
      icon: <GavelIcon className={styleClasses.challengeIcon} />,
      title: 'Risk of fines and legal action',
    },
    {
      icon: <DescriptionIcon className={styleClasses.challengeIcon} />,
      title: 'Managing contracts and policies effectively',
    },
    {
      icon: <SecurityIcon className={styleClasses.challengeIcon} />,
      title: 'Ensuring data privacy and security compliance',
    },
  ];

  return (
    <Box className={styleClasses.whyLegalContainer}>
      {/* Main heading */}
      <Typography variant="h4" className={classes.h4font}>
        Why Legal & Compliance Matter
      </Typography>
      {/* Subtext */}
      <Typography variant="body1" className={classes.description} gutterBottom>
        Legal risks and compliance failures can lead to hefty fines, reputational damage,
        and operational disruptions. Without expert guidance, navigating complex
        regulations can be challenging.
      </Typography>
      <br/>
      <Typography variant="h5" className={classes.h5fontPara} gutterBottom>
        Common Challenges
      </Typography>
      <br/>
      {/* Grid of 4 challenges with icons on blue background and text below */}
      <Grid container spacing={2} justifyContent="center">
        {challenges.map((challenge, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box className={styleClasses.iconContainer}>
                {challenge.icon}
              </Box>
              <Typography
                variant="subtitle1"
                className={classes.subtitlefont}
                style={{ marginTop: theme.spacing(1) }}
              >
                {challenge.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// ────────────────────────────────────────────────────────────────────────────────
// MAIN COMPONENT: LegalCompliance
// ────────────────────────────────────────────────────────────────────────────────
const LegalCompliance = () => {
  useEffect(() => {
    const mainTitle = 'Best Online Courses | OpenGrowth.AI | Growth Academy';
    const subtitle = 'Discover the best online courses at OpenGrowth.AI Growth Academy and elevate your career with expert-led, practical learning programs.';
    document.title = `${mainTitle} - ${subtitle}`;
  }, []);

  const classes = useGlobalStyles();
  const styleClasses = useStyles();
  const theme = useTheme();
  const location = useLocation();

  const [showPopup, setShowPopup] = useState(false);
  const [popupClosed, setPopupClosed] = useState(false);

  // AlertBox state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  // Handle popup closure
  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupClosed(true);
  };

  return (
    <Box style={{ padding: 8, paddingTop: 0 }}>
      {/* Banner */}
      <Suspense fallback={<div>Loading Banner...</div>}>
        <Banner />
      </Suspense>
      {/* Updated WhyLegalCompliance section */}
      <Suspense fallback={<div>Loading Why Legal & Compliance Matter...</div>}>
        <WhyLegalCompliance />
      </Suspense>
      <Suspense fallback={<div>Loading How We Solve It...</div>}>
        <HowWeSolveIt />
      </Suspense>
      {/* FAQ Section */}
      <Suspense fallback={<div>Loading FAQs...</div>}>
        <LazyLoadComponent>
          <FAQSection />
        </LazyLoadComponent>
      </Suspense>
    </Box>
  );
};

export default LegalCompliance;
