import { makeStyles } from '@material-ui/core/styles';
import cookie from 'react-cookies';
let config_res=cookie.load('config')	 


 export let menuItems = [
  {
    // Changed Hire to Expert and added link to it -- shrithi
    menu: 'Growth Services',
    submenu: [
      { label: 'Growth Selling as a Service', link: '/growth-selling-as-a-service' },
      { label: 'EOR Services', link: '/eor-services.html' },
      //{ label: 'Growth Advisory', link: '/growth-advisory' },
      // { label: 'Growth CoPilot', link: '/growth-copilot' },
      // { label: 'Growth Academy', link: '/growth-academy' },
      { label: 'Venture Assistance Services', link: '/venture-assistance-services' },
    ],

    //Commented the submenu --shrithi
    // submenu: [
    //   { label: 'Experts on demand', link: 'https://www.opengrowth.com/mentorship' },
    // ],
  },

  //commenting grow --Shrithi
  // {
  //   menu: 'Grow',
  //   submenu: [
  //     { label: 'Digital Marketing', link: 'https://www.opengrowth.com/services/digital-marketing' },
  //     { label: 'Social Media Marketing', link: 'https://www.opengrowth.com/services/smm' },
  //     { label: 'SEO Marketing', link: 'https://www.opengrowth.com/services/seo' },
  //     { label: 'Content Marketing', link: 'https://www.opengrowth.com/services/content-marketing' },
  //     { label: 'Website Development', link: 'https://www.opengrowth.com/services/website-development' },
  //     { label: 'UI/UX', link: 'https://www.opengrowth.com/services/ux-ui' },
  //   ],
  // },
{
    menu: 'About Us',
    link: '/about-us',
    submenu: [],
  },
  {
    menu: 'Our Team',
    link: '/team',
    submenu: [],
  },
  {
    menu: 'Insights',
    link: 'https://www.blogs.opengrowth.com/',
    submenu: [],
  },
  
  
//   {
//   menu: window.location.pathname === '/' || window.location.pathname === '/login'  ?'Join Now': 'Login' ,
//   link: window.location.pathname === '/' || window.location.pathname === '/login' ?'/register/user': '/login' ,
//   submenu: [],
// }

];
 export let cimpmenuItems = [
  
  {
  menu: window.location.pathname === '/' || window.location.pathname === '/login'  ?'Join Now': 'Login' ,
  link: window.location.pathname === '/' || window.location.pathname === '/login' ?'/register/user': '/login' ,
  submenu: [],
}

];