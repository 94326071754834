import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

/**
 * Replace these imports with your actual images
 */
import questionIcon from '../assets/About us/Question_mark.png';
import bulbIcon from '../assets/About us/Bulb.png';
import useGlobalStyles from '../styles/CourseStyles';

/**
 * Example color references from the screenshot:
 *  - Dark blue: #25387c
 *  - Blue box:  #1f3c88 (or darker #25387c)
 *  - Yellow box: #fcb900
 *  - Gray box:   #999999 or #adb5bd
 *
 * Adjust them to match your design exactly.
 */

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    color: '#25387c',
    marginTop: '5rem',
    padding: theme.spacing(4, 2),
    position: 'relative',
    boxSizing: 'border-box',
    // Adjust top margin and padding on mobile devices
    [theme.breakpoints.down('sm')]: {
      marginTop: '3rem',
      padding: theme.spacing(2),
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '24px',
    marginLeft: theme.spacing(2),
  },
  verticalLine: {
    position: 'absolute',
    top: '150px', // adjust as needed
    left: '50%',
    height: '80%',
    width: '2px',
    backgroundColor: '#ccc',
    transform: 'translateX(-50%)',
    zIndex: 1,
    // Hide the timeline line on mobile for clarity
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  twoCardsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    margin: theme.spacing(0, 'auto', 8, 'auto'),
    position: 'relative',
    zIndex: 2,
    // ----- NEW: Adjust for tablet (960px) and below -----
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%', // or '80%', as you prefer
      margin: theme.spacing(0, '0', 6, '0'),
    },
    // Existing: for very small screens
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  h4font: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1, // Ensure it takes available space
    fontWeight: 'bold',
    padding: theme.spacing(0, 8, 0, 0),
    // ----- NEW: Adjust for tablet (960px) and below if needed -----
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0),
      textAlign: 'center',
    },
  },
  firstCard: {
    width: '45%',
    backgroundColor: '#25387c', // Blue box
    color: '#fff',
    padding: theme.spacing(3),
    borderRadius: '8px',
    position: 'relative',
    // ----- NEW: tablet responsiveness -----
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginTop: theme.spacing(4),
    },
    // Existing for small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
    },
  },
  cardTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    fontSize: '18px',
  },
  bulletRow: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: '100px',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    // Reduce icon size on mobile devices
    [theme.breakpoints.down('sm')]: {
      width: '70px',
      marginRight: theme.spacing(1),
    },
  },
  bulletText: {
    fontSize: '14px',
    lineHeight: 1.6,
  },
  leftCard: {
    width: '45%',
    backgroundColor: '#25387c', // Blue box
    color: '#fff',
    padding: theme.spacing(3),
    borderRadius: '8px',
    // ----- NEW: tablet responsiveness -----
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(4),
    },
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
    },
  },
  yellowCard: {
    width: '45%',
    backgroundColor: '#fcb900', // Yellow box
    color: '#000',
    padding: theme.spacing(3),
    borderRadius: '8px',
    // ----- NEW: tablet responsiveness -----
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
    },
  },
  grayCard: {
    width: '45%',
    backgroundColor: '#adb5bd', // Gray box
    color: '#000',
    padding: theme.spacing(3),
    borderRadius: '8px',
    // ----- NEW: tablet responsiveness -----
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
    },
  },
}));

export default function WhyChooseOpenGrowth() {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  return (
    <Box className={classes.root}>
      {/* Vertical timeline line */}
      <Box className={classes.verticalLine} />

      {/* 1. Title and First Card in the same row */}
      <Box className={classes.twoCardsRow}>
        {/* Title as a separate box */}
        <Typography
          variant="h4"
          className={`${styleClasses.h4font} ${classes.h4font}`}
        >
          Why Choose OpenGrowth.AI?
        </Typography>

        {/* First card */}
        <Box className={classes.firstCard}>
          <Typography variant="h5" className={styleClasses.h5fontPara} gutterBottom>
            Limited Access to Expert Guidance
          </Typography>

          {/* Bullet #1 */}
          <Box className={classes.bulletRow}>
            <img src={questionIcon} alt="Question" className={classes.icon} />
            <Typography variant="body1" className={styleClasses.body1font}>
              Startups and also matured enterprises urgently need specialized
              expertise but lack the resources to hire full-time experts,
              leading to stalled projects and critical mistakes due to
              insufficient guidance.
            </Typography>
          </Box>

          {/* Bullet #2 */}
          <Box className={classes.bulletRow}>
            <img src={bulbIcon} alt="Bulb" className={classes.icon} />
            <Typography variant="body1" className={styleClasses.body1font}>
              OpenGrowth provides startups and enterprises with immediate
              access to a global network of seasoned experts and executives
              available for on-demand or fractional hiring, allowing them to
              quickly address specific challenges and fill critical skill
              gaps.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* 2. Next row: Left (blue card) / Right (yellow card) */}
      <Box className={classes.twoCardsRow}>
        {/* Left card - Lack of Practical, Experiential Learning Opportunities */}
        <Box className={classes.leftCard}>
          <Typography variant="h5" className={styleClasses.h5fontPara} gutterBottom>
            Lack of Practical, Experiential Learning Opportunities
          </Typography>
          <Box className={classes.bulletRow}>
            <img src={questionIcon} alt="Question" className={classes.icon} />
            <Typography variant="body1" className={styleClasses.body1font}>
              Entrepreneurs struggle with applying theoretical knowledge to
              real-world challenges, causing frustration and setbacks when
              standard education doesn’t address their immediate practical
              needs.
            </Typography>
          </Box>
          <Box className={classes.bulletRow}>
            <img src={bulbIcon} alt="Bulb" className={classes.icon} />
            <Typography variant="body1" className={styleClasses.body1font}>
              Through comprehensive courses on leadership, marketing, strategy,
              AI, and more, OpenGrowth offers experiential learning that
              empowers entrepreneurs and teams to gain practical knowledge
              essential for growth.
            </Typography>
          </Box>
        </Box>

        {/* Right card - Inefficient Team Collaboration and Organization */}
        <Box className={classes.yellowCard}>
          <Typography variant="h5" className={styleClasses.h5fontPara} gutterBottom>
            Inefficient Team Collaboration and Organization
          </Typography>
          <Box className={classes.bulletRow}>
            <img
              src={questionIcon}
              alt="Question"
              className={classes.icon}
              style={{
                filter:
                  'invert(60%) sepia(66%) saturate(5000%) hue-rotate(220deg) brightness(72%) contrast(100%)',
              }}
            />
            <Typography variant="body1" className={styleClasses.body1font}>
              Startups face chaos and miscommunication due to disorganized
              workflows and lack of a unified collaboration platform, resulting
              in missed deadlines and decreased productivity.
            </Typography>
          </Box>
          <Box className={classes.bulletRow}>
            <img
              src={bulbIcon}
              alt="Bulb"
              className={classes.icon}
              style={{
                filter:
                  'invert(60%) sepia(66%) saturate(5000%) hue-rotate(220deg) brightness(72%) contrast(100%)',
              }}
            />
            <Typography variant="body1" className={styleClasses.body1font}>
              The OpenGrowth Hub serves as a virtual collaboration platform
              where startups can organize tasks, track progress, and collaborate
              effectively, enhancing productivity and operational efficiency.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* 3. Final row: Left (blue card) / Right (gray card) */}
      <Box className={classes.twoCardsRow}>
        {/* Left card - Feeling Isolated Without a Supportive Community */}
        <Box className={classes.leftCard}>
          <Typography variant="h5" className={styleClasses.h5fontPara} gutterBottom>
            Feeling Isolated Without a Supportive Entrepreneurial Community
          </Typography>
          <Box className={classes.bulletRow}>
            <img src={questionIcon} alt="Question" className={classes.icon} />
            <Typography variant="body1" className={styleClasses.body1font}>
              Entrepreneurs often feel alone in their journey, lacking a network
              to share ideas, receive feedback, and gain moral support, which
              leads to decreased motivation and increased stress, projects and
              critical mistakes due to insufficient guidance.
            </Typography>
          </Box>
          <Box className={classes.bulletRow}>
            <img src={bulbIcon} alt="Bulb" className={classes.icon} />
            <Typography variant="body1" className={styleClasses.body1font}>
              OpenGrowth fosters communities for entrepreneurs, mentors, and
              experts to connect, share ideas, and support each other, providing
              a network that facilitates collaboration and mutual growth.
            </Typography>
          </Box>
        </Box>

        {/* Right card - Inability to Access Fractional Expertise */}
        <Box className={classes.grayCard}>
          <Typography variant="h5" className={styleClasses.h5fontPara} gutterBottom>
            Inability to Access Fractional Expertise for Specific Needs
          </Typography>
          <Box className={classes.bulletRow}>
            <img
              src={questionIcon}
              alt="Question"
              className={classes.icon}
              style={{
                filter:
                  'invert(60%) sepia(66%) saturate(5000%) hue-rotate(220deg) brightness(72%) contrast(100%)',
              }}
            />
            <Typography variant="body1" className={styleClasses.body1font}>
              Startups require niche expertise on a temporary or part-time
              basis but find it nearly impossible to engage professionals
              willing to work fractionally, leading to project delays and
              immense frustration.
            </Typography>
          </Box>
          <Box className={classes.bulletRow}>
            <img
              src={bulbIcon}
              alt="Bulb"
              className={classes.icon}
              style={{
                filter:
                  'invert(60%) sepia(66%) saturate(5000%) hue-rotate(220deg) brightness(72%) contrast(100%)',
              }}
            />
            <Typography variant="body1" className={styleClasses.body1font}>
              By combining expert hiring, learning resources, collaboration
              tools, and community engagement into a single platform, OpenGrowth
              simplifies growth management, reducing the complexity of juggling
              multiple services and tools.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
